import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { blogList } from "../../redux/slices/blogSlice";
import BlogCard from "./BlogCard";
import { Trans, useTranslation } from "react-i18next";
import Loading from "../../partials/Loader";
const Blogs = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.blog);
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(blogList());
  }, [dispatch]);


  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="w-[80%] mx-auto flex justify-between items-center bg-white my-6">
        <div className="w-[100%] flex justify-between items-center">
          <h2 className="text-[32px]">
            <Trans i18nKey="description.bloglistpart1"></Trans>
          </h2>
          <Link
            to="/blogs"
            className="px-[24px] py-[12px] bg-[#F1F1F1] border-[2px] border-solid border-[#F1F1F1] rounded-[100px] text-[12px]"
          >
            Hamısına bax
          </Link>
        </div>
      </div>
      <div className="w-[80%] mx-auto grid grid-cols-1 lg:grid-cols-3 gap-12">
        {data?.slice(0, 3)?.map((blog) => {
          return <BlogCard blog={blog} />;
        })}
      </div>
    </>
  );
};

export default Blogs;
