import React from "react";
import { useTranslation, Trans } from "react-i18next";
import NavBar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
const About = () => {
  const { i18n } = useTranslation();

  return (
    <div className="mt-4">
      <NavBar />
      <div className="bg-[#092256] text-center py-[50px]">
        <h2 className=" text-[#7EAFE8] text-[16px] text-center w-[60%] mx-auto">
          Vectus Portu vasitəsilə tədarik zəncirinizə şəffaflıq, nəzarət və
          səmərəlilik qazandırın, bütün bunlarla bərabər karbon emissiyasını
          azaltmağa addım atın! Logistikanı gələcəyə daşıyırıq.
        </h2>
      </div>
      <div className="w-[80%] mx-auto pt-[30px] flex lg:flex-row flex-col gap-8">
        <div>
          <figure className="w-[400px]">
            <img
              alt=""
              src="https://www.kamion.co/wp-content/themes/kamion/img/hakkimizda.webp"
            />
          </figure>
        </div>
        <div>
          <h3 className="text-[#0085FF] text-[16px]">Bizim Haqqımızda</h3>
          <p className="font-semibold text-[32px] pt-4">
            Yük daşımalarınıza Nəzarət və Şəffaflıq əlavə etmək üçün
            Texnologiyadan istifadə edirik.
          </p>

          <p>
            <br />
            Vectus Port 2024-cü ildə Azərbaycan qurulan logistika sahəsində
            fəaliyyət göstərən texnologiya şirkətidir. Şirkətlərə FTL və LTL yük
            daşımalarında səmərəli, davamlı və rəqəmsal texnologiyalar istifadə
            edərək logistika xidmətləri təqdim edir.
            <br />
            <br />
            Vectus Port olaraq biz logistika şirkəti kimi təchizat zəncirinizə
            inteqrasiya oluruq. Müqavilə bağladığınız daşıma proseslərində
            Vectus Port sizinlə bərabər bütün nəqliyyat prosesinin idarə
            edilməsində sizə yardımcı olur.
            <br />
            <br />
            Yüzlərlər yük maşınından ibarət daşıyıcı bazamızla yük göndərənlərə
            və daşıyıcılara səmərəli iş prosesi qurmağa və karbon emissiyasının
            azaltmağa şərait yaradırıq.
            <br />
            <br />
            Vectus Port ilə siz bütün logistika iş proseslərinizi bir
            platformada idarə edə, təchizat zəncirinizdə şəffaflıq əldə edə və
            ətraf mühitə müsbət təsir göstərə bilərsiniz.
            <br />
            <br />
          </p>
        </div>
      </div>
      <div>
        <h3 className="w-[80%] mx-auto text-center py-8 text-[20px]">
          Vectus Portun xüsusi xidmətlərindən yararlanın və sadəcə etibarlı
          yükdaşıyıcıları ilə işinizi etibarlı edin.
        </h3>
      </div>
      <div>
        <h3 className="w-[80%] mx-auto text-center py-8 text-[20px]">
          <b>Vectus Port®</b>ilə yükdaşımalarına nəzarət edin.
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default About;
