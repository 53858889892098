import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  trackingUpdateSuccess: null,
  trackingUpdateLoading: false,
  trackingUpdateError: null,
  trackingStatusUpdateSuccess: null,
  trackingStatusUpdateLoading: false,
  trackingStatusUpdateError: null,
};

export const trackingList = createAsyncThunk(
  "tracking/trackingList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/tracking/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const trackingUpdate = createAsyncThunk(
  "car/carUpdate",
  async ({ id,car }, { rejectWithValue }) => {
    console.log(car);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/tracking/${id}/assign`,
        car,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const trackingStatusUpdate = createAsyncThunk(
  "tracking/trackingStatusUpdate",
  async ({ id,status }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/tracking/${id}/${status}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(trackingList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(trackingList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(trackingList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(trackingUpdate.pending, (state) => {
        state.trackingUpdateLoading = true;
        state.trackingUpdateError = null;
      })
      .addCase(trackingUpdate.fulfilled, (state, action) => {
        state.trackingUpdateLoading = false;
        state.trackingUpdateSuccess = action.payload.success;
      })
      .addCase(trackingUpdate.rejected, (state, action) => {
        state.trackingUpdateLoading = false;
        state.trackingUpdateError = action.payload;
      })
      .addCase(trackingStatusUpdate.pending, (state) => {
        state.trackingStatusUpdateLoading = true;
        state.trackingStatusUpdateError = null;
      })
      .addCase(trackingStatusUpdate.fulfilled, (state, action) => {
        state.trackingStatusUpdateLoading = false;
        state.trackingStatusUpdateSuccess = action.payload.success;
      })
      .addCase(trackingStatusUpdate.rejected, (state, action) => {
        state.trackingStatusUpdateLoading = false;
        state.trackingStatusUpdateError = action.payload;
      });
  },
});

// Export actions and reducer
export const trackingActions = trackingSlice.actions;
export default trackingSlice.reducer;
