import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { blogList } from "../../redux/slices/blogSlice";
import BlogCard from "../../components/Home/BlogCard";
import Loading from "../../partials/Loader";
import Footer from "../../components/Home/Footer";
import Navbar from "../../components/Home/NavBar";
const BlogList = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.blog);
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(blogList());
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />
      <div className="w-[80%] mx-auto flex justify-between items-center bg-white my-6">
        <div className="w-[100%] flex justify-between items-center">
          <h2 className="text-[32px]">
            <Trans i18nKey="description.bloglistpart1"></Trans>
          </h2>
        </div>
      </div>
      <div className="w-[80%] mx-auto w-[80%] mx-auto grid-cols-1 lg:grid grid-cols-3 gap-12">
        {data?.map((blog) => {
          return <BlogCard blog={blog} />;
        })}
      </div>
      <Footer />
    </>
  );
};

export default BlogList;
