import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const Form = () => {
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setmessage] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");

  const resetForm = () => {
    // Use the form's reset method to clear input values
    document.getElementById("contactForm").reset();
    // Optionally, you can also reset the state values
    setName("");
    setEmail("");
    setmessage("");
    setSurname("");
    setPhone("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contact = {
      name,
      surname,
      message,
      email,
      phone,
    };
    try {
      const response = true;
      if (response && response.payload) {
        Swal.fire({
          title: "Success",
          html: "Your message has been sent successfully!",
          timer: 4000,
          timerProgressBar: true,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
          onClose: () => {
            // Reset the form after successful submission
            resetForm();
          },
        });
      }
    } catch (error) {
      // Catch any other errors
      Swal.fire({
        title: "Error",
        text: "An error occurred while adding the product. Please try again!",
        icon: "error",
      });
    }
  };

  return (
    <div className="w-[80%] mx-auto py-8">
      <div>
        <div className="grid grid-cols-2 gap-4 pt-8">
          <div>
            <h3 className="text-[24px] pb-[20px] text-white ">
              Əlaqə
            </h3>
          </div>
        </div>

        <div className="iform">
         

          <form className="form" >
            <div className="if0 py-6">
              <label className="text-white">MÖVZU SEÇİN</label>
              <div class="btn-group bootstrap-select kmainselect">
                <select
                  onchange="formTypeOnChange()"
                  className="border-[#F1F1F1] border-solid border-[1px] mt-4 px-4 py-4 outline-none text-[12px] rounded-[5px] w-[100%]"
                  required=""
                  tabindex="-98"
                >
                  <option>Seçim Yapınız</option>
                  <option value="Yükveren">Yük Verən olmaq istəyirəm</option>
                  <option value="Taşıyıcı">Yük Daşıyan olmaq istəyirəm</option>
                  <option value="Diğer">Digər</option>
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="pb-2 text-white">AD</label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="border-[1px] border-solid border-[#F1F1F1] rounded-[5px] outline-none px-2 py-4"
                  required=""
                />
              </div>
              <div className="flex flex-col">
                <label className="pb-2 text-white">SOYAD</label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  className="border-[1px] border-solid border-[#F1F1F1] rounded-[5px] outline-none px-2 py-4"
                  required=""
                />
              </div>
              <div className="flex flex-col">
                <label className="pb-2 text-white">TELEFON</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="border-[1px] border-solid border-[#F1F1F1] rounded-[5px] outline-none px-2 py-4"
                  required=""
                />
              </div>
              <div className="flex flex-col">
                <label className="pb-2 text-white">E-POÇT</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="border-[1px] border-solid border-[#F1F1F1] rounded-[5px] outline-none px-2 py-4"
                  required=""
                />
              </div>
            </div>
            <div className="flex flex-col py-4">
              <label className="pb-2 text-white">ŞİRKƏT</label>
              <input
                type="text"
                name="company_name"
                id="company_name"
                className="border-[1px] border-solid border-[#F1F1F1] rounded-[5px] outline-none px-2 py-4"
                required=""
              />
            </div>
            <div className="flex flex-col">
              <label className="pb-2 text-white">AÇIQLAMA</label>
              <textarea
                type="text"
                name="description"
                id="description"
                className="border-[1px] border-solid border-[#F1F1F1] rounded-[5px] outline-none px-2 py-4"
                required=""
              ></textarea>
            </div>

            <div className="pt-2">
              <label className="form-check-label text-white opacity-[0.7]">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value="1"
                  name="kvkk"
                  id="checkboxone"
                />{" "}
                Vectus Portdan reklam və məlumatlandırıcı e-poçt mətnləri və bu
                məqsədlə əlaqə məlumatlarımın yadda saxlanılmasına və
                istifadəsinə razılıq verirəm.
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>

            <div class="temiz"></div>
            <div class="form-check b-check">
              <label className="form-check-label text-white opacity-[0.7]">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  value="1"
                  name="kvkk"
                  id="checkboxtwo"
                />{" "}
                Müqavilə mətnini oxudum və qəbul edirəm.
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>

            <div className="text-white my-4 w-[100%] rounded-[5px] rounded-[5px]">
              <button
                type="button"
                className="bg-[#0085FF] w-[100%] rounded-[5px] py-4"
                onclick="sendForm()"
              >
                Mesaj Göndər
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
