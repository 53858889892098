import React from "react";
import Navbar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
import { Link } from "react-router-dom";
import Questions from "../../components/Home/Questions";
import CustomerForm from "../../components/Home/CustomerForm";
import shipperImage from "../../assets/images/shipper.png";
const CustomerScreen = () => {
  const advantages = [
    {
      id: 1,
      title: "Təcrübəli komanda və Vaxtında Çatdırılma",
      imgUrl: "	https://www.kamion.co/wp-content/themes/kamion/img/yk1.webp",
    },
    {
      id: 2,
      title: "Etibarlı və Effektiv İdarəetmə",
      imgUrl: "	https://www.kamion.co/wp-content/themes/kamion/img/yk2.webp",
    },
    {
      id: 3,
      title: "Canlı İzləmə və Hesabatlar",
      imgUrl: "	https://www.kamion.co/wp-content/themes/kamion/img/yk3.webp",
    },
    {
      id: 4,
      title: "Yeni Texnologiyanın Tətbiqi",
      imgUrl: "	https://www.kamion.co/wp-content/themes/kamion/img/yk4.webp",
    },
    {
      id: 5,
      title: "Karbon Emisiyasının Azaldılması",
      imgUrl: "	https://www.kamion.co/wp-content/themes/kamion/img/yk5.webp",
    },
    {
      id: 6,
      title: "Təcrübəli komanda və Vaxtında Çatdırılma",
      imgUrl: "	https://www.kamion.co/wp-content/themes/kamion/img/yk6.webp",
    },
  ];
  return (
    <div>
      <Navbar />
      <div className="bg-[#092256] text-center py-[10px] lg:py-[30px]">
        <h1 className="text-center text-[#7EAFE8] text-[14px] lg:text-[20px] py-[15px] lg:py-[40px]">
          Rəqəmsal Logistika Təcrübəsi
        </h1>
        <h2 className="text-white text-[20px] lg:text-[32px]">
          Bütün logistika proseslərinizi bir platformadan idarə edin
        </h2>
        <div className="pt-[30px] text-white gap-4 flex lg:flex-row flex-col items-center justify-center">
          <Link
            to="/register/customer"
            className="text-[14px] lg:text-[20px] px-[24px] py-[6px] lg:py-[16px] bg-[#7BB9FA] border-[2px] border-solid border-[#7BB9FA] rounded-[100px]"
          >
            İNDİ QEYDİYYATDAN KEÇ
          </Link>
          <Link className="border-[2px] text-[14px] lg:text-[20px] border-solid border-[#fff] px-[24px] py-[6px] lg:py-[16px] rounded-[100px]">
            SİZƏ ZƏNG EDƏK
          </Link>
        </div>
        <div className="w-[80%] mx-auto lg:w-[100%] pt-[30px] flex items-center justify-center">
          <figure>
            <img alt="Yük Verenler" src={shipperImage} />
          </figure>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col items-center gap-4  mt-16 w-[95%] lg:w-[80%] mx-auto">
        <div className="border-[2px] border-solid border-[#ECF5FF] p-[40px] rounded-[8px] cursor-pointer">
          <h3 className="text-[22px]">
            <b>LTL YÜKDAŞIMA</b>
          </h3>
          <p className="text-[16px] opacity-[0.6] pt-2">
            Yükünüzün həcmindən asılı olmayaraq bizim tərəfdaşlarımızdan sürətli
            və sərfəli qiymət alın.
          </p>
          <button className="bg-[#0085FF] text-white px-[24px] py-[12px] mt-6 rounded-[100px]">
            <Link to="/register/driver">İNDİ QEYDİYYATDAN KEÇ</Link>
          </button>
        </div>
        <div className="border-[2px] border-solid border-[#ECF5FF] p-[40px] rounded-[8px]">
          <h3 className="text-[22px]">
            <b>FTL YÜK DAŞIMA</b>
          </h3>
          <p className="text-[16px] opacity-[0.6] pt-2">
            Böyük həcmli yükləriniz üçün uyğun daşıyıcılardan sürətli təklif
            alın.
          </p>
          <button className="bg-[#0085FF] text-white px-[24px] py-[12px] mt-6 rounded-[100px]">
            <Link to="/register/driver">İNDİ QEYDİYYATDAN KEÇ</Link>
          </button>
        </div>
      </div>
      <h3 className="text-center text-[#092256] text-[24px] py-[20px] lg:py-[50px]">
        Yük Verən Paneli Nədir?
      </h3>
      <div className="w-[95%] lg:w-[80%] mx-auto flex lg:flex-row flex-col lg:items-center justify-between gap-6">
        <div className="lg:w-[80%]">
          <ul className="flex flex-wrap">
            <li className="py-[20px] px-[24px] flex  gap-2 cursor-pointer">
              <span className="text-[12px]">01</span>
              <div>
                <h4 className="text-[#031742] text-[14px] lg:text-[22px] opacity-[0.8]">
                  Qeydiyyatdan keç və Tələblərini qeyd et
                </h4>
                <p className="opacity-[0.7]  text-[10px] lg:text-[14px] pt-2">
                  Asan şəkildə qeydiyyatdan keçərək, bütün yükdaşıma
                  tələblərinizi qeyd edin, uyğun qiymət təklifi ilə təcrübəli
                  daşıyıcılar ilə yükünüzü təhlükəsiz daşımasın təmin edə
                  bilərsiniz.
                </p>
              </div>
            </li>
            <li className="py-[20px] px-[24px] flex  gap-2 cursor-pointer">
              <span className="text-[12px]">02</span>
              <div>
                <h4 className="text-[#031742] text-[14px] lg:text-[22px] opacity-[0.8]">
                  Yük Daşımalarını İzlə
                </h4>
                <p className="opacity-[0.7] text-[10px] lg:text-[14px] pt-2">
                  Daşıma statuslarını izləyərək, logistik proseslərinizi daha
                  effektiv formada idarə edin.
                </p>
              </div>
            </li>
            <li className="py-[20px] px-[24px] flex  gap-2 cursor-pointer">
              <span className="text-[12px]">03</span>
              <div>
                <h4 className="text-[#031742] text-[14px] lg:text-[22px] opacity-[0.8]">
                  Göndəriş və Çatdırılma Prosesini İdarə et
                </h4>
                <p className="opacity-[0.7]  text-[10px] lg:text-[14px] pt-2">
                  Göndəriş sənədlərini və yük maşınlarını asanlıqla qarşılaşdır,
                  şirkətin maliyyə idarəetməsini asanlaşdıraraq xərclərinizi
                  nəzarətdə saxlaya bilərsiniz.
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div class="tab-pane active">
          <img
            alt="Nasil Çalışır"
            src="https://www.kamion.co/wp-content/themes/kamion/img/sp-nasil-1.webp"
          />
        </div>
      </div>
      <div className="bg-[#F2F3F4] mt-4">
        <div className="w-[95%] lg:w-[80%] mx-auto">
          <h2 className="text-[20px] py-[28px]">Üstünlüklər</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {advantages?.map((advantage) => {
              return (
                <div className="rounded-[15px] relative w-[100%]">
                  <div>
                    <img
                      className="rounded-[15px] w-[100%]"
                      src={advantage?.imgUrl}
                      alt="advantage"
                    />
                  </div>
                  <div>
                    <h3 className="absolute bottom-[10%] left-[5%] text-[24px] text-white">
                      {advantage?.title}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div>
        <Questions />
      </div>
      <div className="bg-[#354684]">
        <CustomerForm />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default CustomerScreen;
