import React, { useEffect } from "react";
import { BsBoxSeam } from "react-icons/bs";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { detailsUser } from "../../../redux/slices/userSlice";
const Home = () => {
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  const { user: data } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(detailsUser());
  }, [dispatch]);


  return (
    <div>
      <div className="w-[100%] grid gap-2 dashboardbox">
        <div className=" relative w-[100%] flex items-center h-[120px] justify-between rounded-[5px] bg-purple-200 hover:bg-purple-300 active:bg-purple-400 py-4 px-5 cursor-pointer gap-8">
          <div className="absolute top-[5%] left-[-1%] overflow-hidden opacity-[0.6]">
            <BsBoxSeam className="w-[100px] h-[100px] text-[26px] text-[white]" />
          </div>
          <div className="absolute right-[5%] float-end text-right z-40">
            <h3 className="text-lg text-right text-purple-700 font-semibold">
              <Trans i18nKey="description.dashboardlistpart1"></Trans>
            </h3>
            <p className=" text-purple-700 text-4xl">
              10
            </p>
          </div>
        </div>
        <div className="relative w-[100%] flex items-center h-[120px] justify-between rounded-[5px] bg-amber-200 hover:bg-amber-300 active:bg-amber-400 py-4 px-5 cursor-pointer gap-8">
          <div className="absolute top-[5%] left-[-1%] overflow-hidden opacity-[0.6]">
            <BsBoxSeam className="w-[100px] h-[100px] text-[26px] text-[white]" />
          </div>
          <div className="absolute right-[5%] float-end text-right z-40">
            <h3 className="text-lg text-right text-amber-700 font-semibold">
              <Trans i18nKey="description.dashboardlistpart2"></Trans>
            </h3>
            <p className=" text-amber-700 text-4xl">
             10
            </p>
          </div>
        </div>
        <div className=" relative w-[100%]  flex items-center h-[120px] justify-between rounded-[5px] bg-emerald-200 hover:bg-emerald-300 active:bg-emerald-400 py-4 px-5 cursor-pointer gap-8">
          <div className="absolute top-[5%] left-[-1%] overflow-hidden opacity-[0.6]">
            <BsBoxSeam className="w-[100px] h-[100px] text-[26px] text-[white]" />
          </div>
          <div className="absolute right-[5%] float-end text-right z-40">
            <h3 className="text-lg text-right text-emerald-700 font-semibold">
              <Trans i18nKey="description.dashboardlistpart3"></Trans>
            </h3>
            <p className=" text-emeralde-700 text-4xl">12</p>
          </div>
        </div>
        <div className=" relative w-[100%] flex items-center h-[120px] justify-between rounded-[5px] bg-red-200 hover:bg-red-300 active:bg-red-400 py-4 px-5 cursor-pointer gap-8">
          <div className="absolute top-[5%] left-[-1%] overflow-hidden opacity-[0.6]">
            <BsBoxSeam className="w-[100px] h-[100px] text-[26px] text-[white]" />
          </div>
          <div className="absolute right-[5%] float-end text-right z-40">
            <h3 className="text-lg text-right text-red-700 font-semibold">
              <Trans i18nKey="description.dashboardlistpart4"></Trans>
            </h3>
            <p className=" text-red-700 text-4xl">18</p>
          </div>
        </div>
        <div className=" relative w-[100%] flex items-center h-[120px] justify-between rounded-[5px] bg-slate-200 hover:bg-slate-300 active:bg-slate-400 py-4 px-5 cursor-pointer gap-8">
          <div className="absolute top-[5%] left-[-1%] overflow-hidden opacity-[0.6]">
            <BsBoxSeam className="w-[100px] h-[100px] text-[26px] text-[white]" />
          </div>
          <div className="absolute right-[5%] float-end text-right z-40">
            <h3 className="text-lg text-right text-slate-700 font-semibold">
              <Trans i18nKey="description.dashboardlistpart5"></Trans>
            </h3>
            <p className=" text-slate-700 text-4xl">0</p>
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default Home;
