import React, { useEffect } from "react";
import Footer from "../../components/Home/Footer";
import Navbar from "../../components/Home/NavBar";
import { blogDetails } from "../../redux/slices/blogSlice";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../partials/Loader";
const BlogDetails = () => {
  const id = window.location.pathname.split("/")[2];

  const dispatch = useDispatch();
  const { blogDetailsData: data, blogDetailsLoading: loading } = useSelector(
    (state) => state.blog
  );

  useEffect(() => {
    dispatch(blogDetails({id}));
  }, [dispatch, id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Navbar />
      <div className="w-[90%] lg:w-[80%] mx-auto my-6">
        <div className="w-[100%]">
          <img
            className="w-[100%] h-[250px] lg:h-[500px] rounded-[10px]"
            src="https://www.kamion.co/wp-content/uploads/2024/06/shutterstock_195043625.webp"
            alt="blog"
          />
        </div>
        <h2 className="py-8 text-[24px] font-bold">{data?.title}</h2>
        <p>{data?.description}</p>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetails;
