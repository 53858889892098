import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  companyDetailsData: null,
  companyDetailsLoading: false,
  companyDetailsError: null,
  detailsCompanyData: null,
  detailsCompanyLoading: false,
  detailsCompanyError: null,
  companyUpdateSuccess: null,
  companyUpdateLoading: false,
  companyUpdateError: null,
  companyDeleteSuccess: null,
  companyDeleteLoading: false,
  companyDeleteError: null,
  companyCreateSuccess: null,
  companyCreateLoading: false,
  companyCreateError: null,
};

export const companyList = createAsyncThunk(
  "company/companyList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/company/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const companyDetails = createAsyncThunk(
  "company/companyDetails",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/company/details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const detailsCompany = createAsyncThunk(
  "company/detailsCompany",
  async ({id}, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/company/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const updateCompany = createAsyncThunk(
  "company/companyUpdate",
  async ({ updatedCompany }, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/company`,
        updatedCompany,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const companyCreate = createAsyncThunk(
  "company/companyCreate",
  async ({ company }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/company`,
        company,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(companyList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(companyList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(companyList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(companyDetails.pending, (state) => {
        state.companyDetailsLoading = true;
        state.companyDetailsError = null;
      })
      .addCase(companyDetails.fulfilled, (state, action) => {
        state.companyDetailsLoading = false;
        state.companyDetailsData = action.payload;
      })
      .addCase(companyDetails.rejected, (state, action) => {
        state.companyDetailsLoading = false;
        state.companyDetailsError = action.payload;
      })
      
      .addCase(updateCompany.pending, (state) => {
        state.companyUpdateLoading = true;
        state.companyUpdateError = null;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.companyUpdateLoading = false;
        state.companyUpdateSuccess = action.payload.success;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.companyUpdateLoading = false;
        state.companyUpdateError = action.payload;
      })
      .addCase(companyCreate.pending, (state) => {
        state.companyCreateLoading = true;
        state.companyCreateError = false;
      })
      .addCase(companyCreate.fulfilled, (state, action) => {
        state.companyCreateLoading = false;
        state.companyCreateSuccess = true;
      })
      .addCase(companyCreate.rejected, (state, action) => {
        state.companyCreateLoading = false;
        state.companyCreateError = true;
      });
  },
});

// Export actions and reducer
export const companyActions = companySlice.actions;
export default companySlice.reducer;
