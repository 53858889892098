import React from "react";
import Card from "./Cards";
const Features = () => {
  const data = [
    {
      id: 1,
      title: "Yüksək texnologiya",
      value:
        "Müştəri panelindən daşımaları izləmə imkanı, sənədlərə və hesabatları istənilən zaman əldə edə bilmə.",
      logo: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
        >
          <path
            d="M27.2388 48.6508L17.5299 19.5751C17.4134 19.2278 17.3962 18.8549 17.48 18.4983C17.5639 18.1417 17.7455 17.8156 18.0045 17.5566C18.2636 17.2975 18.5897 17.1159 18.9463 17.032C19.3029 16.9482 19.6758 16.9654 20.0231 17.0819L49.0988 26.783C49.4931 26.9152 49.8354 27.1689 50.0766 27.5077C50.3178 27.8464 50.4457 28.2529 50.4417 28.6687C50.4378 29.0846 50.3023 29.4885 50.0547 29.8226C49.8071 30.1567 49.4601 30.4039 49.0633 30.5287L36.2822 34.5462C35.9794 34.6404 35.7038 34.8063 35.4788 35.0299C35.2539 35.2534 35.0863 35.528 34.9903 35.8302L30.9806 48.6153C30.8568 49.0135 30.6098 49.3621 30.2752 49.6109C29.9406 49.8597 29.5357 49.9959 29.1187 49.9998C28.7018 50.0037 28.2943 49.8753 27.9551 49.6328C27.6158 49.3904 27.3623 49.0465 27.231 48.6508H27.2388Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M35.5215 35.0242L49.181 48.6797"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M2 13.5579L8.58949 15.3264"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M4.79688 29.4123L9.62182 24.5913"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M13.5654 2L15.33 8.58556"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M29.4167 4.79492L24.5918 9.61593"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      id: 2,
      title: "Şəffaf qiymətləndirmə",
      value:
        "Xüsusi qiymət algoritması ilə sürətli və sabit təklif ala bilərsiz.",
      logo: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
        >
          <path
            d="M13.084 25.0626C13.084 26.7774 13.7652 28.4219 14.9777 29.6344C16.1902 30.8469 17.8347 31.5281 19.5494 31.5281C21.2642 31.5281 22.9087 30.8469 24.1212 29.6344C25.3337 28.4219 26.0149 26.7774 26.0149 25.0626C26.0149 23.3479 25.3337 21.7034 24.1212 20.4909C22.9087 19.2783 21.2642 18.5972 19.5494 18.5972C17.8347 18.5972 16.1902 19.2783 14.9777 20.4909C13.7652 21.7034 13.084 23.3479 13.084 25.0626Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M35.6573 32.4276C32.1549 41.8191 23.2215 49.9988 19.5491 49.9988C14.9309 49.9988 2 37.0679 2 25.0607C1.99978 22.6189 2.50913 20.204 3.49546 17.9703C4.4818 15.7366 5.92342 13.7333 7.72813 12.0885C9.53285 10.4438 11.6609 9.19373 13.9763 8.41834C16.2917 7.64295 18.7434 7.35928 21.1747 7.58546"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M28.5153 14.0669C27.2813 13.8489 27.2813 12.0792 28.5153 11.8649C30.6992 11.4853 32.7206 10.4637 34.3215 8.93053C35.9225 7.39736 37.0305 5.42207 37.5042 3.25661L37.578 2.91671C37.8441 1.69751 39.5805 1.69382 39.8576 2.90932L39.9463 3.30464C40.438 5.46069 41.5561 7.42327 43.1601 8.94569C44.764 10.4681 46.7822 11.4825 48.9609 11.8612C50.1986 12.0755 50.1986 13.8563 48.9609 14.0705C46.7822 14.4493 44.764 15.4636 43.1601 16.9861C41.5561 18.5085 40.438 20.4711 39.9463 22.6271L39.8576 23.0224C39.5805 24.2379 37.8441 24.2305 37.578 23.0113L37.5042 22.6714C37.0305 20.506 35.9225 18.5307 34.3215 16.9975C32.7206 15.4644 30.6992 14.4465 28.5153 14.0669Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      id: 3,
      title: "Müştəri Xidmətləri",
      value:
        "Sizin üçün təyin olunmuş təcrübəli əməkdaşlarımız yük daşıma prosesi müddətində sizin xidmətinizdə olacaq.",
      logo: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="52"
          viewBox="0 0 48 52"
          fill="none"
        >
          <path
            d="M27.4357 48.9298L25.267 49.7791C24.485 50.0736 23.6231 50.0736 22.8411 49.7791L20.6724 48.9298C15.1794 46.7666 10.4613 42.9934 7.13123 38.1008C3.8012 33.2082 2.01333 27.4225 2 21.4956V11.2309C6.16268 11.8268 10.4083 11.2839 14.2896 9.65939C18.1709 8.03484 21.5442 5.38879 24.0541 2C28.6486 8.72009 36.2573 11.8955 46.1081 11.2309V21.4956C46.0948 27.4225 44.3069 33.2082 40.9769 38.1008C37.6469 42.9934 32.9287 46.7666 27.4357 48.9298Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      id: 4,
      title: "Daha az Karbon Emissiyası",
      value:
        "Vectus Port hazırladığı texnologiya ilə yük maşınlarının boş olaraq yolda olmasını qarşısın almağa çalışaraq CO2-in azaldılmasına kömək edir.",
      logo: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="53"
          height="52"
          viewBox="0 0 53 52"
          fill="none"
        >
          <path
            d="M36.8902 15.4064C36.6241 14.6519 36.1304 13.9986 35.4773 13.5364C34.8242 13.0743 34.0438 12.8261 33.2438 12.8262H30.2462C29.3977 12.8307 28.5806 13.1474 27.9507 13.716C27.3209 14.2845 26.9225 15.0651 26.8315 15.9087C26.7404 16.7522 26.9632 17.5998 27.4573 18.2896C27.9513 18.9794 28.682 19.4632 29.51 19.6486L34.071 20.6465C35.0017 20.8521 35.8238 21.3938 36.3799 22.168C36.9359 22.9422 37.1867 23.8943 37.0843 24.8419C36.9818 25.7895 36.5332 26.666 35.8246 27.3034C35.1159 27.9408 34.1969 28.2943 33.2438 28.2962H30.6635C29.8636 28.2956 29.0835 28.0473 28.4305 27.5852C27.7775 27.1231 27.2837 26.4701 27.0171 25.7159"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M31.9541 12.8235V8.95312"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M31.9541 32.1614V28.291"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M13.3975 20.5587C13.3975 25.4807 15.3527 30.2012 18.8332 33.6816C22.3136 37.162 27.0341 39.1173 31.9561 39.1173C36.8782 39.1173 41.5987 37.162 45.0791 33.6816C48.5595 30.2012 50.5148 25.4807 50.5148 20.5587C50.5148 15.6366 48.5595 10.9161 45.0791 7.43571C41.5987 3.95528 36.8782 2 31.9561 2C27.0341 2 22.3136 3.95528 18.8332 7.43571C15.3527 10.9161 13.3975 15.6366 13.3975 20.5587Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M4.24748 22.5938C2.37244 26.0482 1.64278 30.0087 2.1637 33.9046C2.68463 37.8004 4.42922 41.4301 7.14598 44.2705C9.86273 47.1109 13.4113 49.0153 17.2801 49.709C21.1488 50.4027 25.138 49.8499 28.6724 48.1304"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
  ];
  return (
    <div className="w-[80%] mx-auto py-[100px]">
      <h2 className="text-[22px]">
        Logistika proseslərinizi bir nöqtədən idarə edin.
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
        {data?.map((card) => {
          return <Card card={card} />;
        })}
      </div>
    </div>
  );
};

export default Features;
