import React from "react";
import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <div className="w-[100%] lg:w-[80%] mx-auto pt-[50px] lg:pt-[150px] text-white text-center lg:text-left">
      <div className="lg:max-w-[70%]">
        <p className="text-[18px] text-lightblue opacity-[0.7]">
          YÜK DAŞIMALARINDA YENİ TEXNOLOGİYA
        </p>
        <br />
        <h2 className="text-[48px] opacity-[0.8] lg:text-left text-center">
          Sürətli, Rahat və Şəffaf
        </h2>
        <br />
        <div className="lg:w-[100%] w-[80%] mx-auto flex lg:flex-row flex-col mt-[50px] gap-4">
          <Link
            to="/login"
            className="text-14-[px] bg-[#0085FF] text-white border-[2px] rounded-[100px] px-[20px] py-[15px] lg:px-[40px]  lg:py-[20px] border-solid border-[#0085FF] "
          >
            Yüküm var
          </Link>
          <Link
            to="/login"
            className="text-[14px] text-white bg-transparent border-[2px]  rounded-[100px] border-solid px-[20px] py-[15px] lg:px-[40px] lg:py-[20px] border-[#0085FF ]"
          >
            Yük Axtarıram
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
