import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  addressDetailsData: null,
  addressDetailsLoading: false,
  addressDetailsError: null,
  addressUpdateSuccess: null,
  addressUpdateLoading: false,
  addressUpdateError: null,
  addressDeleteSuccess: null,
  addressDeleteLoading: false,
  addressDeleteError: null,
  addressCreateSuccess: null,
  addressCreateLoading: false,
  addressCreateError: null,
};

export const addressList = createAsyncThunk(
  "address/addressList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/address/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const addressDetails = createAsyncThunk(
  "address/addressDetails",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/address/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAddress = createAsyncThunk(
  "address/addressUpdate",
  async ({ id, updatedBan }, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/bans/${id}`,
        updatedBan,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const addressCreate = createAsyncThunk(
  "address/addressCreate",
  async ({ address }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/address`,
        address,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const addressDelete = createAsyncThunk(
  "address/addressDelete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${apiConfig.API_URL}/api/address/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addressList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addressList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(addressList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addressDetails.pending, (state) => {
        state.addressDetailsLoading = true;
        state.addressDetailsError = null;
      })
      .addCase(addressDetails.fulfilled, (state, action) => {
        state.addressDetailsLoading = false;
        state.addressDetailsData = action.payload;
      })
      .addCase(addressDetails.rejected, (state, action) => {
        state.addressDetailsLoading = false;
        state.addressDetailsError = action.payload;
      })

      .addCase(updateAddress.pending, (state) => {
        state.addressUpdateLoading = true;
        state.addressUpdateError = null;
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        state.addressUpdateLoading = false;
        state.addressUpdateSuccess = action.payload.success;
      })
      .addCase(updateAddress.rejected, (state, action) => {
        state.addressUpdateLoading = false;
        state.addressUpdateError = action.payload;
      })
      .addCase(addressCreate.pending, (state) => {
        state.addressCreateLoading = true;
        state.addressCreateError = false;
      })
      .addCase(addressCreate.fulfilled, (state, action) => {
        state.addressCreateLoading = false;
        state.addressCreateSuccess = true;
      })
      .addCase(addressCreate.rejected, (state, action) => {
        state.addressCreateLoading = false;
        state.addressCreateError = true;
      })
      .addCase(addressDelete.pending, (state) => {
        state.addressDeleteLoading = true;
        state.addressDeleteError = false;
      })
      .addCase(addressDelete.fulfilled, (state, action) => {
        state.addressDeleteLoading = false;
        state.addressDeleteSuccess = true;
      })
      .addCase(addressDelete.rejected, (state, action) => {
        state.addressDeleteLoading = false;
        state.addressDeleteError = true;
      });
  },
});

// Export actions and reducer
export const addressActions = addressSlice.actions;
export default addressSlice.reducer;
