import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { contactCreate } from "../../redux/slices/contactSlice";
import Swal from "sweetalert2";

const Form = () => {
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();

  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = {
        subject,
        name,
        surname,
        phone,
        email,
        companyName,
        description,
      };
      const response = await dispatch(contactCreate({ data }));
      if (response.payload && response.payload !== 0) {
        Swal.fire({
          title: "Uğurlu",
          html: "Mesajınız uğurla tamamlanmışdır",
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
         // Replace with your desired URL
          },
        });
      } else {
        Swal.fire("Error!", "Please try again!", "error");
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "An error occurred while adding the product. Please try again!",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-[80%] mx-auto py-8">
      <div>
        <div>
          <h1 className="text-[32px] text-center">Əlaqə</h1>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-8">
          <div>
            <h3 className="text-[24px] pb-[20px] border-b-[1px] border-b-solid border-b-[#F1F1F1]">
              Əlaqə
            </h3>
            <div className="py-4 border-b-[1px] border-b-solid border-b-[#F1F1F1]">
              <h4 className="text-[18px] pb-4">ÜNVAN </h4>
              Atatürk prospekti 80, Nərmanov rayonu, Bakı, Azərbaycan
            </div>
            <div className="flex items-center justify-between py-6">
              <div className="">
                <b>Email</b>
              </div>
              <div className="flex items-center gap-4">
                <div className="">
                  <b>info@vectusport.com</b>
                </div>
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                  >
                    <circle cx="24" cy="24" r="24" fill="#F1F1F1"></circle>
                    <path
                      d="M32.2857 16H15.5238C15.1197 16 14.7321 16.1605 14.4463 16.4463C14.1605 16.7321 14 17.1197 14 17.5238V30.4762C14 30.8803 14.1605 31.2679 14.4463 31.5537C14.7321 31.8395 15.1197 32 15.5238 32H32.2857C32.6899 32 33.0774 31.8395 33.3632 31.5537C33.649 31.2679 33.8095 30.8803 33.8095 30.4762V17.5238C33.8095 17.1197 33.649 16.7321 33.3632 16.4463C33.0774 16.1605 32.6899 16 32.2857 16Z"
                      stroke="#092256"
                      stroke-width="1.71429"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M14 17.9062L22.9295 23.2762C23.2038 23.4377 23.5482 23.5245 23.9048 23.5245C24.2613 23.5245 24.6057 23.4377 24.88 23.2762L33.8095 17.9062"
                      stroke="#092256"
                      stroke-width="1.71429"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="py-[20px] border-t-[1px] border-t-solid border-t-[#F1F1F1]">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.3935845434953!2d49.850364311589544!3d40.40013087132317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d5d5a2c1005%3A0x5ede1cc1418ccc54!2sGanjlik%20Mall!5e0!3m2!1sen!2saz!4v1719401093151!5m2!1sen!2saz"
                allowfullscreen=""
                width="100%"
                height="360"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          <div className="iform">
            <h3 className="text-[24px] pb-2">Mesaj Göndər</h3>
            <h4 className="text-[16px] opacity-[0.7] pb-[20px] border-b-[1px] border-b-solid border-b-[#F1F1F1]">
              Bizə yazaraq daha çox məlumat əldə edin.
            </h4>

            <form className="form" onSubmit={handleSubmit}>
              <div className="if0 py-6">
                <label>MÖVZU SEÇİN</label>
                <div class="btn-group bootstrap-select kmainselect">
                  <select
                    onchange={(e) => setSubject(e.target.value)}
                    className="border-[#F1F1F1] border-solid border-[1px] mt-4 px-4 py-4 outline-none text-[12px] rounded-[5px] w-[100%]"
                    required=""
                    tabindex="-98"
                  >
                    <option>Seçim edin</option>
                    <option value="Yükveren">Yük Verən olmaq istəyirəm</option>
                    <option value="Taşıyıcı">
                      Yük Daşıyan olmaq istəyirəm
                    </option>
                    <option value="Diğer">Digər</option>
                  </select>
                </div>
              </div>
              <div className="grid grid-cols-1  lg:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="pb-2">AD</label>
                  <input
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    className="border-[1px] border-solid border-[#F1F1F1] rounded-[5px] outline-none px-2 py-4"
                    required=""
                  />
                </div>
                <div className="flex flex-col">
                  <label className="pb-2">SOYAD</label>
                  <input
                    type="text"
                    onChange={(e) => setSurName(e.target.value)}
                    className="border-[1px] border-solid border-[#F1F1F1] rounded-[5px] outline-none px-2 py-4"
                    required=""
                  />
                </div>
                <div className="flex flex-col">
                  <label className="pb-2">TELEFON</label>
                  <input
                    type="number"
                    onChange={(e) => setPhone(e.target.value)}
                    className="border-[1px] border-solid border-[#F1F1F1] rounded-[5px] outline-none px-2 py-4"
                    required=""
                  />
                </div>
                <div className="flex flex-col">
                  <label className="pb-2">E-POÇT</label>
                  <input
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    className="border-[1px] border-solid border-[#F1F1F1] rounded-[5px] outline-none px-2 py-4"
                    required=""
                  />
                </div>
              </div>
              <div className="flex flex-col py-4">
                <label className="pb-2">ŞİRKƏT</label>
                <input
                  type="text"
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="border-[1px] border-solid border-[#F1F1F1] rounded-[5px] outline-none px-2 py-4"
                  required=""
                />
              </div>
              <div className="flex flex-col">
                <label className="pb-2">AÇIQLAMA</label>
                <textarea
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                  className="border-[1px] border-solid border-[#F1F1F1] rounded-[5px] outline-none px-2 py-4"
                  required=""
                ></textarea>
              </div>

              <div className="pt-2">
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="1"
                    name="kvkk"
                    id="checkboxone"
                  />{" "}
                 Vectus Portdan reklam və məlumatlandırıcı e-poçt mətnləri və bu məqsədlə əlaqə məlumatlarımın yadda saxlanılmasına və istifadəsinə razılıq verirəm.

                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>

              <div class="temiz"></div>
              <div class="form-check b-check">
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="1"
                    name="kvkk"
                    id="checkboxtwo"
                  />{" "}
                 Müqavilə mətnini oxudum və qəbul edirəm.
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>

              <div className="text-white my-4 w-[100%] rounded-[5px] rounded-[5px]">
                <button
                  type="submit"
                  className="bg-[#0085FF] w-[100%] rounded-[5px] py-4"
                >
                  {isLoading ? "Loading..." : " Mesaj Göndər "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
