import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          description: {
            homepart1: "Fast Shipment Abroad.",
            homepart2:
              "Kavvay manages your international shipment process seamlessly and automatically, helping you to send airmail to certain countries at affordable prices.",
            homepart3: "Register For Free",
            homepart4: "INTEGRATIONS",
            homepart5:
              "Speed up your shipments with Kavvay helpers and start making your shipments at affordable prices.",
            homepart6: "for more information on integrations .",
            homepart7: "PARTNERS",
            homepart8: "FREQUENTLY ASKED QUESTIONS",
            homepart9: "Contact",
            homepart10: "Contact information",
            homepart11:
              "Address: Nispetiye, Gazi Gucnar street, Uygur Business Center No 4/2, Beshiktash, Istanbul,34000",
            homepart12: "OUR ADVANTAGES",
            homepart13: "Send",

            contactpart1: "Write your name",
            contactpart2: "Write your surname",
            contactpart3: "Write your email",
            contactpart4: "Write your message",
            contactpart5: "Write your phone",

            Rpoart0: "Reset Password",

            footerpart0: "Company",
            footerpart1: "About",
            footerpart2: "FAQ",
            footerpart3: "Blo1",
            footerpart4: "Legal",
            footerpart5: "GDPR",
            footerpart6: "Privacy Policy",
            footerpart7: "Terms & conditions",
            footerpart8: "Cookie Policy",
            footerpart9: "Compliance Statement",
            footerpart10: "Security profile",
            footerpart11: "Contact",
            footerpart12: "Support",
            footerpart13: "2024 All rights reserved by Kavvay",

            navpart1: "Customer",
            navpart2: "Driver",
            navpart3: "About",
            navpart4: "Bloq",
            navpart5: "Contact",
            navpart6: "Sign in",
            navpart7: "Register",

            aboutpart1: "About",
            aboutpart2:
              "OPTIMIZE THE POST-PURCHASE EXPERIENCE FOR eCOMMERCE WITH KAVVAY",
            aboutpart3:
              "We are a young and fast growing business born in Turkey and looking to expand across the globe with team of technology and retail experts spread across different countries and different regions. We like to build high performance solutions for eCommerce businesses and have an unrelenting drive to build things fast and with outstanding quality.",
            aboutpart4:
              "We are aware that companies are experiencing an important cargo process in the background. We set out with the idea of ​​making your life easier and managing all your cargo processes. While providing   an easy shipping experience to cargo companies, we offer the most suitable cargo alternatives with dynamic pricing and fast delivery.",
            aboutpart5:
              "To businesses with Geliver; We provide various opportunities by       getting advantageous prices from all cargo companies with which they integrate. We are aware that cargo processes should be very practical now, we are here.",
            aboutpart6: "Your shipping process is much easier with us!",
            aboutpart7: "Why Come?",
            aboutpart8: "Cargo Alternative",
            aboutpart9:
              "You can have the opportunity to work with all cargo companies at the same time, or you can choose the most suitable one for you from the cargo companies we have listed for you.",
            aboutpart10: "Advantageous Price",
            aboutpart11:
              "You can have the opportunity to work with all cargo companies at the same time, or you can choose the most suitable one for you from the cargo companies we have listed for you.",
            aboutpart12: "Personalized Process",
            aboutpart13:
              "You can have the opportunity to work with all cargo companies at the same time, or you can choose the most suitable one for you from the cargo companies we have listed for you.",
            aboutpart14: "Ease of Use",
            aboutpart15:
              "You can have the opportunity to work with all cargo companies at the same time, or you can choose the most suitable one for you from the cargo companies we have listed for you.",

            epart1: "Click’n’Ship",
            epart2: "Come Easily with Geliver!",
            epart3:
              "It manages all your shipping processes; We provide fast, easy and cheap cargo service",
            epart4:
              "Click’n’Ship is the ultimate shipping solution for your business. Start shipping right away with pre negotiated rates and without having to deal with multiple delivery partners.",
            epart5: "Add your shipments",
            epart6:
              "Create shipments through a dashboard, with a spreadsheet or by using the Kavvay API and Kavvay eCommerce Connectors. Shipments are automatically assigned to the cheapest, fastest or most reliable delivery partner through an AI-driven automation engine.Alternatively, you can also select your preferred delivery service manually.",
            epart7: "Print and manage labels",
            epart8:
              "Pack your orders, print the label and you are ready to ship! The delivery partner gets notified about your delivery.",
            epart9: "Track your orders.",
            epart10:
              "Automatically track your orders and get alerted of any late deliveries, exceptions or unsatisfied customers. Offer your customers an exceptional experience by adding your own tracking page and setting up automated email or SMS notifications with information about the status of the shipment. Manage expectations by monitoring and adjusting expected delivery dates to improve your service levels. Use advanced reports to assess your past performance and make improvements to your operations.",

            calpart1: "Calculate Delivery Price",
            calpart2: "Choose Country",
            calpart3: "Height",
            calpart4: "Width",
            calpart5: "Length",
            calpart6: "Weight",
            calpart7: "Price:",
            calpart8: "Calculate",
            calpart9: "Reset",
            calpart10:
              "While calculating the weight of the shipments, the weight on the scale and the volumetric weight (desi) are taken as basis. (Length * Width * Height) / 5000 = Weight",
            calpart11:
              "Compare the current weight of the package with its volumetric weight. The heavier of the two weights is the billable weight and must be used to calculate the price.",
            calpart12:
              "Your shipments may not require customs clearance. Customs fees and taxes are calculated based on the content and value of your items and your countrys regulations.",

            calinputplaceholderpart1: "Max. 150 cm",
            calinputplaceholderpart2: "Max. 120 cm",

            calinputplaceholderpart3: "Max. 240 cm",

            calinputplaceholderpart4: "Max. 30 cm",

            calerrorpart1: "Please select country",

            calerrorpart2: "Please add height",
            calerrorpart3: "Please add width",
            calerrorpart4: "Please add length",
            calerrorpart5: "Please add weight",
            calvalidateerrorpart1: "Max height 150 cm",
            calvalidateerrorpart2: "Mak width 120 cm",
            calvalidateerrorpart3: "Mak length 240 cm",
            calvalidateerrorpart4: "Mak weight 30 kg",
            loginpart1: "Sign in",
            loginpart2: "or",
            loginpart3: "Create a new account",
            loginpart4: "remember me",
            loginpart5: "Forgot Password",
            loginpart6: "Login",

            menupart1: "Home Page",
            menupart2: "Profile",
            menupart3: "Settings",
            menupart4: "Customer Details",
            menupart5: "Account",
            menupart6: "Invoices",
            menupart7: "New Shipment",
            menupart8: "Calculate Price",
            menupart9: "Shippings",
            menupart10: "Payment",
            menupart11: "Frequently Asked Questions",
            menupart12: "Announcements",
            menupart13: "Support",
            profilepart0: "Contact details",
            profilepart1: "Name",
            profilepart2: "Surname",
            profilepart3: "Email",
            profilepart4: "Phone",

            registerpart1: "with Geliver",
            registerpart2: "good luck!",
            registerpart3: "Send cargo fast or find cargo with Vectus Port!",
            registerpart4: "Register",
            registerpart5: "or",
            registerpart6: "Sign in",
            registerpart7: "Name:",
            registerpart8: "Last name:",
            registerpart9: "Email:",
            registerpart10: "Telephone:",
            registerpart11: "Password:",
            registerpart12: "Password Again:",
            registerpart131: "Personal Data Protection",
            registerpart132: "Commercial Electronic Message Confirmation Text",
            registerpart133: "User Agreement",
            registerpart134: "Cookie Policy",
            registerpart14: "read and approve.",
            registerpart15: "Register",

            afterloginpart1: "Profile:",
            afterloginpart2: "Shipping Count:",
            afterloginpart3: "Subscription status:",
            afterloginpart4: "Balance:",
            invoiceaddresspart0: "Company details",
            invoiceaddresspart1: "Company name",
            invoiceaddresspart2: "Phone  number",
            invoiceaddresspart3: "VOEN",
            invoiceaddresspart4: "Web site",
            invoiceaddresspart5: "City",
            invoiceaddresspart6: "Province",
            invoiceaddresspart7: "State",
            invoiceaddresspart8: "Address",
            invoiceaddresspart9: "Postal code",
            invoiceaddresspart10: "Company phone",
            invoiceaddresspart11: "PBX",

            invoiceinputaddresspart1: "Write company name",
            invoiceinputaddresspart2: "Write phone  number",
            invoiceinputaddresspart3: "Write VOEN",
            invoiceinputaddresspart4: "Write web site",
            invoiceinputaddresspart5: "Write city",
            invoiceinputaddresspart6: "Write province",
            invoiceinputaddresspart7: "Write state",
            invoiceinputaddresspart8: "Write address",
            invoiceinputaddresspart9: "Write postal code",
            invoiceinputaddresspart10: "Write company phone",
            invoiceinputaddresspart11: "Write PBX",

            accountpassswordpart1: "Password Change:",
            accountpassswordpart2: "Old Password:",
            accountpassswordpart3: "New Password:",
            accountpassswordpart4: "Confirm Password:",
            accountpassswordpart5: "Change Password",

            invoicepart1: "Card Selection",

            createshipmentpart1: "Choose truck",
            createshipmentpart2: "Truck number",
            createshipmentpart3: "Truck type",
            createshipmentpart4: "Truck case type",
            createshipmentpart5: "Fire document",
            createshipmentpart6: "Route",
            createshipmentpart7: "Pick up address",
            createshipmentpart8: "Pick up date",
            createshipmentpart9: "Pick up responsible person",
            createshipmentpart10: "Phone number",
            createshipmentpart11: "Delivery location",
            createshipmentpart12: "Delivery date",
            createshipmentpart13: "Delivery responsible person",
            createshipmentpart14: "Phone number",
            createshipmentpart15: "Save",
            createshipmentpart16: "Order created successfully",
            createshipmentpart17: "Description",
            createshipmentpart18: "Load details",
            createshipmentpart19: "Shipment type",
            createshipmentpart20: "Load type",
            createshipmentpart21: "Description",
            createshipmentpart22: "Corparative shipment",
            createshipmentpart23: "Price offer",
            createshipmentpart24: "Payment type",
            createshipmentpart25: "Choose",
            createshipmentpart26: "International",
            createshipmentpart30: "Olke daxili",
            createshipmentpart28: "Add address",
            createshipmentpart29: "Add break address",
            createshipmentpart30: "Save",
            createshipmentinputpart2: "Choose truck number",
            createshipmentinputpart3: "Choose truck type",
            createshipmentinputpart4: "Choose truck case type",
            createshipmentinputpart5: "Choose fire document",
            createshipmentinputpart7: "Write pick up location",
            createshipmentinputpart8: "Write pick up date",
            createshipmentinputpart9: "Write pick up responsible person",
            createshipmentinputpart10: "Write phone number",
            createshipmentinputpart11: "Write delivery location",
            createshipmentinputpart12: "Write delivery date",
            createshipmentinputpart13: "Write delivery responsible person",
            createshipmentinputpart14: "Write phone number",
            createshipmentinputpart17: "Write description",
            createshipmentinputpart19: "Choose shipment type",
            createshipmentinputpart192: "Shipment type",
            createshipmentinputpart20: "Choose load type",
            createshipmentinputpart21: "Write description",
            createshipmentinputpart22: "Choose type",
            createshipmentinputpart23: "Write price offer",
            createshipmentinputpart24: "Choose payment type",
            createshipmentinputpart25: "Write address title",
            createshipmentinputpart26: "Choose address",

            orderinputplaceholder1: "Adınızı yazln",
            orderinputplaceholder2: "Soyadınızı yazın",
            orderinputplaceholder3: "E-poçt ünvanınızı yazın",
            orderinputplaceholder4: "Ölkənizi seçin",
            orderinputplaceholder5: "Şəhərinizi yazın",
            orderinputplaceholder6: "İlçenizi yazınız",
            orderinputplaceholder7: "Ünvanınızı yazın",
            orderinputplaceholder8: "Poçta kodunuzu yazın",
            orderinputplaceholder9: "Açıqlamanı yazın",
            orderinputplaceholder10: "Write address title",

            bloglistpart1: "Blog",

            shippingspart1: "Order",
            shippingspart2: "Order N/",
            shippingspart3: "Pick up l.",
            shippingspart4: "Delivery up l.",
            shippingspart5: "Pick up date",
            shippingspart6: "Delivery date",
            shippingspart7: "Order type",
            shippingspart8: "Status",
            shippingspart9: "Close",
            shippingspart10: "Create order",
            shippingspart11: "Actions",

            editshippingspart0: "Edit Shipping Data",
            editshippingspart1: "ID",
            editshippingspart2: "Payment Status",
            editshippingspart3: "Status",
            editshippingspart4: "Label",
            editshippingspart5: "Receiver",
            editshippingspart6: "Order Number",
            editshippingspart7: "Country",
            editshippingspart8: "Count",
            editshippingspart9: "Value",
            editshippingspart10: "Date",
            editshippingspart11: "Upload Invoice",
            editshippingspart12: "Upload Invoice",
            editshippingspart13: "Update",
            editshippingspart14: "Cancel",

            driverpart1: "Drivers",
            driverpart2: "Create driver",
            driverpart3: "Full name",
            driverpart4: "Phone number",
            driverpart5: "Fin code",
            driverpart6: "Status",
            driverpart11: "Actions",

            createdriverpart1: "Create driver",
            createdriverpart2: "Full name",
            createdriverpart3: "Phone number",
            createdriverpart4: "Fin code",
            createdriverpart5: "ID (front)",
            createdriverpart6: "ID (back)",
            createdriverpart7: "Driver license",
            createdriverpart8: "Click to upload",

            createdriverinputpart2: "Write full name",
            createdriverinputpart3: "Write full number",
            createdriverinputpart4: "Write FIN code",

            createcarpart1: "Add lorry",
            createcarpart2: "Driver",
            createcarpart3: "Lorry type",
            createcarpart4: "Ban type",
            createcarpart5: "Vehicle registration plate",
            createcarpart6: "Brand",
            createcarpart7: "Model",
            createcarpart8: "Registration year",
            createcarpart9: "Volume m3",
            createcarpart10: "Ton",
            createcarpart11: "Vehicle photo",
            createcarpart12: "Vehicle registration certificate (front)",
            createcarpart13: "Vehicle registration certificate (back)",
            createcarpart14: "İnsurance",
            createcarpart15: "Lease contract",
            createcarpart16: "Insurance valid till",
            createcarpart17: "Lease",
            createcarpart18: "Lease contract",
            createcarpart19: "Lease start date",
            createcarpart20: "Lease end date",
            createcarpart21: "GOV",
            createcarpart22: "GOV License",

            createcarinputpart2: "Choose driver",
            createcarinputpart3: "Choose lorry type",
            createcarinputpart4: "Choose ban type",
            createcarinputpart5: "Write vehicle registration plate",
            createcarinputpart6: "Choose brand",
            createcarinputpart7: "Choose model",
            createcarinputpart8: "Choose registration year",
            createcarinputpart9: "Write volume m3",
            createcarinputpart10: "Write tonnnage",
            createcarinputpart14: "Insurance",
            createcarinputpart16: "Choose insurance valid till",
            createcarinputpart17: "Lease",
            createcarinputpart19: "İcarə başlama tarixinı yazın",
            createcarinputpart20: "İcarə bitmə tarixi yazın",
            createcarinputpart21: "GOV registration",
            createcarinputpart22: "GOV license",

            cardetailspart1: "Truck details",
            cardetailspart2: "Truck number",
            cardetailspart3: "Truck type",
            cardetailspart4: "Truck brand",
            cardetailspart5: "Truck model",
            cardetailspart6: "Truck ban type",
            cardetailspart7: "Truck tonnage",
            cardetailspart8: "Truck volume",
            cardetailspart9: "Truck insurance valid till",
            cardetailspart10: "Vehicle photo",
            cardetailspart11: "Vehicle registration certificate (front)",
            cardetailspart12: "Vehicle registration certificate (back)",
            cardetailspart13: "Insurance",
            cardetailspart14: "Lease contract",

            profilepartprimarytitle: "PROFILE",
            profilepartname: "Name",
            profilepartsurname: "Surname",
            profilepartemail: "Email",
            profilepartphone: "Phone",
            profilepartprimarybtn: "Update",
            profilepartsecondarytitle: "Security",
            profilepartoldpassword: "Old Password",
            profilepartnewpassword: "New Password",
            profilepartconfirmpassword: "Confirm Password",
            profilepartsecondarybtn: "Change Password",

            dashboardlistpart1: "Confirmed",
            dashboardlistpart2: "Waiting",
            dashboardlistpart3: "On the way",
            dashboardlistpart4: "Delivered",
            dashboardlistpart5: "Completed",

            adminsidebarpart1: "Dashboard",
            adminsidebarpart2: "Profile",
            adminsidebarpart3: "Shipping List",
            adminsidebarpart4: "Create forwarder",
            adminsidebarpart5: "Drivers",
            adminsidebarpart6: "Trucks",
            adminsidebarpart7: "Users",
            adminsidebarpart8: "Company",
            adminsidebarpart9: "Brands",
            adminsidebarpart10: "Ban types",
            adminsidebarpart11: "Car types",
            adminsidebarpart12: "Load types",
            adminsidebarpart13: "Payment types",

            agentsidebarpart1: "Dashboard",
            agentsidebarpart2: "Profile",
            agentsidebarpart3: "Company",
            agentsidebarpart4: "Create shipping",
            agentsidebarpart5: "Shipping List",
            agentsidebarpart6: "Address list",
            agentsidebarpart9: "Log out",

            providersidebarpart1: "Dashboard",
            providersidebarpart2: "Profile",
            providersidebarpart3: "Company",
            providersidebarpart4: "Orders",
            providersidebarpart5: "Drivers",
            providersidebarpart6: "Trucks",

            tablepart1: "Actions",
            registerinputplaceholder1: "Write your password",
            registerinputplaceholder2: "Write your password again",

            alert1: "Your profile has not yet been verified",
            alert2: "Your customer account has not been approved.",
          },
        },
      },
      tr: {
        translation: {
          description: {
            homepart1: "Yurtdışına Hızlı Gönderi",
            homepart2:
              "Kavvay, uluslararası gönderi sürecinizi sorunsuzca ve otomatik şekilde yönetir, belirli ülkelere uygun fiyattan havayolu ile gönderi yapmanıza yardımcı olur.",
            homepart3: "Ücretsiz Kayıt Ol",
            homepart4: "ENTEGRASYONLAR",
            homepart5:
              "Gönderilerinizi, Kavvay altyapısıyla hızlandırın ve uygun fiyatlardan gönderilerinizi yapmaya başlayın.",
            homepart6: "Entegrasyonlar hakkında daha fazla bilgi için.",
            homepart7: "Partnerlerimiz",
            homepart8: "SIKÇA SORULAN SORULAR",
            homepart9: "Əlaqə məlumatları",
            homepart10: "Əlaqə məlumatları",
            homepart11:
              "Adres: Nispetiye Mahallesi, Gazi Güçnar sokak, Uygur İş Merkezi No 4/2, Beşiktaş, İstanbul, 34000",
            homepart12: "BİZİM AVANTAJLARIMIZ",
            homepart13: "Göndər",

            contactpart1: "Zəhmət olmasa adınızı yazın",
            contactpart2: "Zəhmət olmasa soyadınızı yazın",
            contactpart3: "Zəhmət olmasa e-poçtunuzu yazın",
            contactpart4: "Zəhmət olmasa telefonu yazın",

            Rpoart0: "Şifrəni yenilə",

            footerpart0: "Şirkət",
            footerpart1: "Haqqımızda",
            footerpart2: "SSS",
            footerpart3: "Bloq",
            footerpart4: "Sözleşmeler",
            footerpart5: "Kişisel Verilerin Korunması",
            footerpart6: "Ticari Elektronik İleti Onay Metni",
            footerpart7: "Kullanıcı Sözleşmesi",
            footerpart8: "Çerez Politikası",
            footerpart9: "Uygunluk bildirimi",
            footerpart10: "güvenlik profili",
            footerpart11: "İletişim",
            footerpart12: "Destek",
            footerpart13:
              "2023 Tüm hakları Kavvay Lojistik ve Teknoloji Anonim Şirketine aittir.",

            navpart1: "Yük verən",
            navpart2: "Yük daşıyan",
            navpart3: "Haqqımızda",
            navpart4: "Bloq",
            navpart5: "Əlaqə",
            navpart6: "Daxil ol",
            navpart7: "Qeydiyyatdan keç",

            loginpart1: "Daxil olun",
            loginpart2: "və ya",
            loginpart3: "Qeydiyyatdan keçin",
            loginpart4: "Məni yadda saxla",
            loginpart5: "Şifrəni unutdum",
            loginpart6: "Daxil ol",

            profilepart0: "Əlaqə məlumatları:",
            profilepart1: "Ad:",
            profilepart2: "Soyad:",
            profilepart3: "E-poçt:",
            profilepart4: "Telefon:",

            registerpart1: "",
            registerpart2: "kolayca gelsin!",
            registerpart3: "Vectus Port ilə sürətli yük göndər vəya yük tap!",
            registerpart4: "Qeydiyyatdan keç",
            registerpart5: "və ya",
            registerpart6: "Daxil ol",
            registerpart7: "Ad",
            registerpart8: "Soyad",
            registerpart9: "E-poçt",
            registerpart10: "Telefon",
            registerpart11: "Şifrə",
            registerpart12: "Təkrar şifrə",
            registerpart131:
              "Qeydiyyatdan keçməklə Şərtləri və razılaşmaları qəbul edirəm",
            registerpart14: "okudum ve onaylıyorum.",
            registerpart15: "Qeydiyyatdan keç",

            accountpassswordpart1: "Şifre Değiştirme:",
            accountpassswordpart2: "Eski Şifre:",
            accountpassswordpart3: "Yeni Şifre:",
            accountpassswordpart4: "Şifreyi Onayla:",
            accountpassswordpart5: "Şifre değiştir",

            invoiceaddresspart0: "Şirkət məlumatları",
            invoiceaddresspart1: "Şirkət adı",
            invoiceaddresspart2: "Telefon nömrəsi",
            invoiceaddresspart3: "VÖEN",
            invoiceaddresspart4: "Veb sayt",
            invoiceaddresspart5: "Şəhər",
            invoiceaddresspart6: "Əyalət",
            invoiceaddresspart7: "Rayon",
            invoiceaddresspart8: "Adres",
            invoiceaddresspart9: "Post kod",
            invoiceaddresspart10: "Şirkət telefonu",
            invoiceaddresspart11: "Şirkət daxili telefon",

            invoiceinputaddresspart1: "Zəhmət olmasa şirkət adını yazın",
            invoiceinputaddresspart2: "Zəhmət olmasa əlaqə nömrəsini yazın",
            invoiceinputaddresspart3: "Zəhmət olmasa VOEN-i yazın",
            invoiceinputaddresspart4: "Zəhmət olmasa sayt ünvanını yazın",
            invoiceinputaddresspart5: "Zəhmət olmasa şəhəri yazın",
            invoiceinputaddresspart6: "Zəhmət olmasa əyaləti yazın",
            invoiceinputaddresspart7: "Zəhmət olmasa rayonu yazın",
            invoiceinputaddresspart8: "Zəhmət olmasa adresi yazın",
            invoiceinputaddresspart9: "Zəhmət olmasa posta kodu yazın",
            invoiceinputaddresspart10: "Zəhmət olmasa şirkət telefonunu yazın",
            invoiceinputaddresspart11:
              "Zəhmət olmasa şirkət daxili telefonunu yazın",

            createshipmentpart1: "Nəqliyyat növü seçimi",
            createshipmentpart2: "Nəqliyyat sayı",
            createshipmentpart3: "Nəqliyyat növü  ",
            createshipmentpart4: "Ban növü",
            createshipmentpart5: "Yanıcı yük?",
            createshipmentpart6: "Marşrut",
            createshipmentpart7: "Yükləmə ünvanı",
            createshipmentpart8: "Yükləmə tarixi",
            createshipmentpart9: "Məsul şəxsin adı",
            createshipmentpart10: "Telefon nömrəsi",
            createshipmentpart11: "Təhvil ünvanı",
            createshipmentpart12: "Təhvil tarixi",
            createshipmentpart13: "Məsul şəxsin adı",
            createshipmentpart14: "Telefon nömrəsi",
            createshipmentpart15: "Yadda saxla",
            createshipmentpart16: "Yük uğurla əlavə edildi",
            createshipmentpart17: "Açıqlama",
            createshipmentpart18: "Yük haqqında məlumatlar",
            createshipmentpart19: "Daşınma forması",
            createshipmentpart20: "Yükün növü",
            createshipmentpart21: "Açıqlama",
            createshipmentpart22: "Korporativ daşıma",
            createshipmentpart23: "Qiymət təklifi",
            createshipmentpart24: "Ödəniş növü",
            createshipmentpart25: "Seçin",
            createshipmentpart26: "Beynəlxalq",
            createshipmentpart27: "Ölkə daxili",
            createshipmentpart28: "Ünvan əlavə et",
            createshipmentpart29: "Ara ünvan əlavə et",
            createshipmentpart30: "Yadda saxla",

            createshipmentinputpart2: "Nəqliyyat sayı seçin",
            createshipmentinputpart3: "Nəqliyyat növü seçin ",
            createshipmentinputpart4: "Ban növünü seçin",
            createshipmentinputpart5: "Seçin",
            createshipmentinputpart7: "Yükləmə ünvanını seçin",
            createshipmentinputpart8: "Yükləmə tarixi yazın",
            createshipmentinputpart9: "Məsul şəxsin adını yazın",
            createshipmentinputpart10: "Telefon nömrəsini yazın",
            createshipmentinputpart11: "Təhvil ünvanını seçin",
            createshipmentinputpart12: "Təhvil tarixini yazın",
            createshipmentinputpart13: "Məsul şəxsin adını yazın",
            createshipmentinputpart14: "Telefon nömrəsi yazın",
            createshipmentinputpart17: "Açıqlama yazın",
            createshipmentinputpart19: "Daşınma formasını seçin",
            createshipmentinputpart192: "tipini seçin",
            createshipmentinputpart20: "Yükün növünü seçin",
            createshipmentinputpart21: "Açıqlama yazın",
            createshipmentinputpart22: "Seçin",
            createshipmentinputpart23: "Qiymət təklifini yazın",
            createshipmentinputpart24: "Ödəniş növünü seçin",
            createshipmentinputpart25: "Ünvan başlığını yazın",
            createshipmentinputpart26: "Ünvanı seçin",

            shippingspart1: "Yüklərim",
            shippingspart2: "Sifariş N/",
            shippingspart3: "Y. ünvanı:",
            shippingspart4: "Y. tarixi",
            shippingspart5: "T. ünvanı",
            shippingspart6: "T. tarixi",
            shippingspart7: "Yük növü",
            shippingspart8: "Status",
            shippingspart9: "Bağla",
            shippingspart10: "Yük yarat",
            shippingspart11: "Actions",

            cardetailspart1: "Nəqliyyat məlumatı",
            cardetailspart2: "Maşın nömrəsi",
            cardetailspart3: "Maşın tipi",
            cardetailspart4: "Maşın markası",
            cardetailspart5: "Maşın modeli",
            cardetailspart6: "Maşın ban type",
            cardetailspart7: "Maşın tonaj",
            cardetailspart8: "Maşın həcmi",
            cardetailspart9: "Sığorta bitmə tarixi",
            cardetailspart10: "Nəqliyyat vasitəsinin şəkli",
            cardetailspart11: "Texniki pasport(ön)",
            cardetailspart12: "Texniki pasport(arxa)",
            cardetailspart13: "Sığorta",
            cardetailspart14: "İcarə müqaviləsi",

            editshippingspart0: "Gönderi Verilerini Düzenle",
            editshippingspart1: "ID",
            editshippingspart2: "Öde",
            editshippingspart3: "Status",
            editshippingspart4: "Etiket",
            editshippingspart5: "Alıcı",
            editshippingspart6: "Sipariş numarası",
            editshippingspart7: "Ülke:",
            editshippingspart8: "Adet",
            editshippingspart9: "Gümrük değeri",
            editshippingspart10: "Tarih",
            editshippingspart11: "Fatura Yükle",
            editshippingspart12: "Dosya seçin",
            editshippingspart13: "Yenilə",
            editshippingspart14: "İptal et",

            createdriverpart1: "Sürücü yarat",
            createdriverpart2: "Ad və soyad",
            createdriverpart3: "Əlaqə nömrəsi",
            createdriverpart4: "Fin kod",
            createdriverpart5: "Şəxsiyyət vəsiqəsi ön hissə",
            createdriverpart6: "Şəxsiyyət vəsiqəsi arxa hissə",
            createdriverpart7: "Sürücülük vəsiqəsinin şəkli",
            createdriverpart8: "Yüklə",

            createdriverinputpart2: "Ad və soyad yazın",
            createdriverinputpart3: "Əlaqə nömrəsi yazın",
            createdriverinputpart4: "Fin kod yazın",

            driverpart1: "Sürücülər",
            driverpart2: "Sürücü yarat",
            driverpart3: "Ad soyad:",
            driverpart4: "Mobil nömrə",
            driverpart5: "Fin kod",
            driverpart6: "Status",
            driverpart11: "Actions",

            createcarpart1: "Nəqliyyat vasitəsi əlavə et",
            createcarpart2: "Sürücü",
            createcarpart3: "Nəqliyyat növü",
            createcarpart4: "Ban növü",
            createcarpart5: "Dövlət nömrə nişanı",
            createcarpart6: "Marka",
            createcarpart7: "Model",
            createcarpart8: "Buraxılış ili",
            createcarpart9: "Həcm",
            createcarpart10: "Tonnaj",
            createcarpart11: "Nəqliyyat vasitəsinin şəkli",
            createcarpart12: "Texniki pasport(ön)",
            createcarpart13: "Texniki pasport(arxa)",
            createcarpart14: "Sığorta",
            createcarpart15: "İcarə müqaviləsi",
            createcarpart16: "Sığorta bitmə tarixi",
            createcarpart17: "İcarə",
            createcarpart18: "İcarə müqaviləsi",
            createcarpart19: "İcarə başlama tarixi",
            createcarpart20: "İcarə bitmə tarixi",
            createcarpart21: "ABADA",
            createcarpart22: "ABADA sertifikatı",

            createcarinputpart2: "Sürücü seçin",
            createcarinputpart3: "Nəqliyyat növü seçin",
            createcarinputpart4: "Ban növü seçin",
            createcarinputpart5: "Dövlət nömrə nişanını yazın",
            createcarinputpart6: "Marka seçin",
            createcarinputpart7: "Model seçin",
            createcarinputpart8: "Buraxılış ilinı yazın",
            createcarinputpart9: "Həcm yazın",
            createcarinputpart10: "Tonnaj yazın",
            createcarinputpart14: "Sığorta",
            createcarinputpart16: "Sığorta bitmə tarixini yazın",
            createcarinputpart17: "İcarə",
            createcarinputpart19: "İcarə başlama tarixinı yazın",
            createcarinputpart20: "İcarə bitmə tarixi yazın",
            createcarinputpart21: "ABADA",
            createcarinputpart22: "ABADA sertifikatı",

            carpart1: "Nəqliyyat vasitələri",
            carpart2: "NV növü",
            carpart3: "Sürücü:",
            carpart4: "Ban növü",
            carpart5: "D.N.N",
            carpart6: "Status",
            carpart11: "Actions",

            profilepartprimarytitle: "PROFİL",
            profilepartname: "Isim",
            profilepartsurname: "Soyisim",
            profilepartemail: "E-poçt",
            profilepartphone: "Telefon",
            profilepartprimarybtn: "Yenilə",
            profilepartsecondarytitle: "Güvenlik",
            profilepartoldpassword: "Eski Şifre",
            profilepartnewpassword: "Yeni Şifre",
            profilepartconfirmpassword: "Şifreyi Onayla",
            profilepartsecondarybtn: "Şifreyi Değiştir",

            agentpart1: "Müşteriler",
            agentpart2: "Müşteri oluştur",
            agentpart3: "Müşteri adı",
            agentpart4: "Abonelik tipi",
            agentpart5: "Vergi numarası",

            agentcreatepart1: "Müşteri oluştur formu",
            agentcreatepart2: "Müşteri adı",
            agentcreatepart3: "Müşteri soyadı",
            agentcreatepart4: "Şirket adı",
            agentcreatepart5: "Ülke",
            agentcreatepart6: "İlçe",
            agentcreatepart7: "Posta kodu",
            agentcreatepart8: "Adres",
            agentcreatepart9: "Telefon numarası",
            agentcreatepart10: "Vergi numarası",
            agentcreatepart11: "Vergi dairesi",
            agentcreatepart12: "Mevcut bakiye",
            agentcreatepart13: " Mecut paket bakiyesi",
            agentcreatepart14: "Müşteri oluştur",

            subscriptionlistpart1: "Abonelikler",
            subscriptionlistpart2: "Abonelik oluştur",
            subscriptionlistpart3: "ID",
            subscriptionlistpart4: "Müşteri ID",
            subscriptionlistpart5: "Paket ID",
            subscriptionlistpart6: "Ödenen tutar",
            subscriptionlistpart7: "Başlangıç tarihi",
            subscriptionlistpart9: "Müşteri adı",
            subscriptionlistpart10: "Paket adı",
            subscriptionlistpart11: "İşlem numarası",
            subscriptionlistpart12: "Bitme tarihi",

            subscriptioncreatepart1: "Abonelik oluştur formu",
            subscriptioncreatepart2: " Paket ID",
            subscriptioncreatepart3: "Paket tutarı",
            subscriptioncreatepart4: "Abonelik tipi",
            subscriptioncreatepart5: "Aboneliği oluştur",
            subscriptioncreatepart6: "Abonelik düzenle formu",
            subscriptioncreatepart7: "Değişiklikleri kaydet",

            packagelistpart1: "Paketler",
            packagelistpart2: "Paket oluştur",
            packagelistpart3: "ID",
            packagelistpart4: "Paket adı",
            packagelistpart5: "Paket fiyatı",
            packagelistpart6: "Paket balans",
            packagelistpart7: "Paket süresi",
            packagelistpart8: "Başlangıç tarihi",

            packagecreatepart1: "Paket oluştur formu",
            packagecreatepart2: "Paket adı",
            packagecreatepart3: "Paket fiyatı",
            packagecreatepart4: "Paket balans",
            packagecreatepart6: "Paket süresi",
            packagecreatepart7: "Paket indirimi",
            packagecreatepart8: "Paketi kaydet",
            packagecreatepart9: "Paket düzenle formu",
            packagecreatepart10: "Paketi düzenle",

            providerlistpart1: "Tedarikçiler",
            providerlistpart2: "Tedarikçi oluştur",
            providerlistpart3: "İsim",
            providerlistpart4: "Ülke",
            providerlistpart5: "Adres",
            providerlistpart6: "TedariKçi düzenle formu",
            providerlistpart45: "Durum",
            providerlistpart7: "Değişiklikleri kaydet",
            providerlistpart8: "Tedarikçi oluştur  formu",
            providerlistpart9: "Tedarikçi düzenle",
            providerlistpart10: "Ülke",
            providerlistpart11: "İl",
            providerlistpart12: "Postal kod",
            providerlistpart13: "Kanuni Adres",
            providerlistpart14: "Adres",
            providerlistpart15: "Telefon numarası",
            providerlistpart16: "Ödeme",
            providerlistpart17: "Zaman",

            countrylistpart1: "Ülkeler",
            countrylistpart2: "Ülke oluştur",
            countrylistpart3: "ID",
            countrylistpart4: "Ülke adı",
            countrylistpart5: "ISO",
            countrylistpart6: "Tedarikçi",
            countrylistpart7: "Ödeme",
            countrylistpart8: "Status",

            dashboardlistpart1: "Sürücü təsdiqlədi",
            dashboardlistpart2: "Yükləmə mərhələsində",
            dashboardlistpart3: "Yoldakı yüklər",
            dashboardlistpart4: "Boşaltma mərhələsində",
            dashboardlistpart5: "Təhvil alındı",
            dashboardlistpart6: "Tamamlandı",
            adminsidebarpart1: "Ana səhifə",
            adminsidebarpart2: "Profil",
            adminsidebarpart3: "Yüklər",
            adminsidebarpart4: "Yük yarat",
            adminsidebarpart5: "Sürücülər",
            adminsidebarpart6: "Nəqliyyat vasitələri",
            adminsidebarpart7: "İstifadəçilər",
            adminsidebarpart8: "Şirkətlər",
            adminsidebarpart9: "Markalar",
            adminsidebarpart10: "Ban növləri",
            adminsidebarpart11: "Nəqliyyat növləri",
            adminsidebarpart12: "Yük formaları",
            adminsidebarpart13: "Ödəniş formaları",

            agentsidebarpart1: "Ana səhifə",
            agentsidebarpart2: "Profil",
            agentsidebarpart3: "Şirkət",
            agentsidebarpart4: "Yük yarat",
            agentsidebarpart5: "Yüklər",
            agentsidebarpart6: "Ünvanlar",
            agentsidebarpart9: "Çıxış",

            providersidebarpart1: "Ana səhifə",
            providersidebarpart2: "Profil",
            providersidebarpart3: "Şirkət",
            providersidebarpart4: "Yüklər",
            providersidebarpart5: "Sürücülər",
            providersidebarpart6: "Nəqliyyat vasitələri",

            tablepart1: "Actions",

            orderinputplaceholder1: "Adınızı yazın",
            orderinputplaceholder2: "Soyadınızı yazın",
            orderinputplaceholder3: "E-poçt ünvanınızı yazın",
            orderinputplaceholder4: "Ölkənizi seçin",
            orderinputplaceholder5: "Şəhərinizi yazın",
            orderinputplaceholder6: "İlçenizi yazınız",
            orderinputplaceholder7: "Ünvanınızı yazın",
            orderinputplaceholder8: "Poçta kodunuzu yazın",
            orderinputplaceholder9: "Açıqlamanı yazın",
            orderinputplaceholder10: "Ünvanınızı yazın",

            bloglistpart1: "Bloq",

            providerpricingcolumn1: "Name",
            providerpricingcolumn2: "From",
            providerpricingcolumn3: "To",
            providerpricingcolumn4: "Price",
            providerpricingcolumn5: "Price list",
            providerpricingcolumn6: "Add price",
            providerpricingcolumn7: "PerKgCalc",

            registerinputplaceholder1: "Şifrənizi yazın",
            registerinputplaceholder2: "Təkrar şifrənizi yazın",

            alert1: "Kullanıcı adınız onaylanmamıştır.",
            alert2: "Müşteri hesabınız hala onaylanmamıştır",
          },
        },
      },
    },
  });

export default i18n;
