import { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Layout from "./layout/layout";
import Home from "./pages/Home/HeroPage";
import Contact  from "./pages/Home/Contact";
import About from "./pages/Home/About";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ResetPassword from "./pages/Auth/ResetPassword";
import Dashboard from "./pages/Dashboard/Shared/Home";
import { detailsUser } from "./redux/slices/userSlice";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./partials/Loader";
import NotFound from "./components/Home/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlogList from "./pages/Home/BlogList";
import BlogDetails from "./pages/Home/BlogDetails";
import Questions from "./pages/Home/FrequentlyAQ";
import CookieConsent from "./components/Home/CookieConsent";
import CustomerScreen from "./pages/Home/CustomerScreen";
import DriverScreen from "./pages/Home/DriverScreen";
import routes from "./routes/index";


function Protected({ children }) {
  const { user } = useSelector(state => state.user);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
}


const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(detailsUser());
    setTimeout(() => setLoading(false), 1000);
  }, [dispatch]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <ToastContainer />
          <CookieConsent />
          <div>
            <Routes>
              <Route index path="/" exact element={<Home />} />
              <Route path="/login" exact element={<Login />} />
              <Route path="/register/:type" exact element={<Register />} />
              <Route path="/contact" exact element={<Contact />} />
              <Route path="/about" exact element={<About />} />
              <Route path="/resetpassword" exact element={<ResetPassword />} />
              <Route path="/customer" exact element={<CustomerScreen />} />
              <Route path="/driver" exact element={<DriverScreen />} />
              <Route path="/blogs" exact element={<BlogList />} />
              <Route path="/blog/:id" exact element={<BlogDetails />} />

              <Route element={<Layout />}>
                <Route index element={<Dashboard />} />
                {routes.map((route, index) => {
                  const {  path, component: Component } = route;
                  return (
                    <Route
                      key={index}
                      path={path}
                      exact={true}
                      element={
                        <Suspense fallback={<Loader />}>
                          <Protected>
                            <Component />
                          </Protected>
                        </Suspense>
                      }
                    />
                  );
                })}
              </Route>
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </div>
        </Router>
      )}
    </>
  );
};

export default App;
