import { lazy } from "react";
const Dashboard = lazy(() => import("../pages/Dashboard/Shared/Home"));
const Profile = lazy(() => import("../pages/Dashboard/Shared/Profile"));
const OrderList = lazy(() => import("../pages/Dashboard/Shared/OrderList"));
const OrderDetails = lazy(() => import("../pages/Dashboard/Shared/OrderDetails"));
const OrderEdit = lazy(() => import("../pages/Dashboard/Shared/OrderEdit"));
const OrderCreate = lazy(() => import("../pages/Dashboard/Shared/OrderCreate"));
const OrderCreateForward = lazy(() => import("../pages/Dashboard/Shared/OrderCreateForward"));
const DriverList = lazy(() => import("../pages/Dashboard/Provider/DriverList"));
const CarList = lazy(() => import("../pages/Dashboard/Provider/CarList"));
const CarCreate = lazy(() => import("../pages/Dashboard/Provider/CarCreate"));
const CarEdit  =  lazy(() => import("../pages/Dashboard/Provider/CarEdit"));
const DriverCreate = lazy(() => import("../pages/Dashboard/Provider/DriverCreate"));
const DriverDetails = lazy(() => import("../pages/Dashboard/Provider/DriverDetails"));
const DriverEdit = lazy(() => import("../pages/Dashboard/Provider/DriverEdit"));
const CompanyDetails = lazy(() => import("../pages/Dashboard/Shared/CompanyDetails"));
const UserList = lazy(() => import("../pages/Dashboard/SuperAdmin/UserList"));
const CompanyList = lazy(() => import("../pages/Dashboard/SuperAdmin/CompanyList"));
const CompanyEdit = lazy(() => import("../pages/Dashboard/Shared/CompanyEdit"));
const AssignCarList = lazy(() => import("../pages/Dashboard/Shared/AssignCar"));
const CarDetails = lazy(() => import("../pages/Dashboard/Provider/CarDetails"));
const BrandList = lazy(() => import("../pages/Dashboard/Provider/BrandList"));
const BrandCreate = lazy(() => import("../pages/Dashboard/Provider/BrandCreate"));
const BrandEdit = lazy(() => import("../pages/Dashboard/Provider/BrandEdit"));
const BanList = lazy(() => import("../pages/Dashboard/Provider/BanList"));
const BanCreate = lazy(() => import("../pages/Dashboard/Provider/BanCreate"));
const BanEdit = lazy(() => import("../pages/Dashboard/Provider/BanEdit"));
const CarTypeList = lazy(() => import("../pages/Dashboard/Provider/CarTypeList"));
const CarTypeCreate = lazy(() => import("../pages/Dashboard/Provider/CarTypeCreate"));
const CarTypeEdit = lazy(() => import("../pages/Dashboard/Provider/CarTypeEdit"));
const LoadTypeList = lazy(() => import("../pages/Dashboard/Provider/LoadTypeList"));
const LoadTypeCreate = lazy(() => import("../pages/Dashboard/Provider/LoadTypeCreate"));
const LoadTypeEdit = lazy(() => import("../pages/Dashboard/Provider/LoadTypeEdit"));
const PaymentTypeList = lazy(() => import("../pages/Dashboard/Provider/PaymentTypeList"));
const PaymentTypeCreate = lazy(() => import("../pages/Dashboard/Provider/PaymentTypeCreate"));
const PaymentTypeEdit = lazy(() => import("../pages/Dashboard/Provider/PaymentTypeEdit"));
const AddressList = lazy(() => import("../pages/Dashboard/Provider/AddressList"));
const AddressCreate = lazy(() => import("../pages/Dashboard/Provider/AddressCreate"));
const AddressEdit = lazy(() => import("../pages/Dashboard/Provider/PaymentTypeEdit"));
const BlogList = lazy(() => import("../pages/Dashboard/SuperAdmin/BlogList"));
const BlogCreate = lazy(() => import("../pages/Dashboard/SuperAdmin/BlogCreate"));
const BlogEdit = lazy(() => import("../pages/Dashboard/SuperAdmin/BlogEdit"))


const routes = [
  {
    path: "/dashboard",
    title: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/profile",
    title: "Profile",
    component: Profile,
  },
  {
    path: "/order-list",
    title: "OrderList",
    component: OrderList,
  },
  {
    path: "/order/:id",
    title: "OrderDetails",
    component: OrderDetails,
  },
  {
    path: "/order/edit/:id",
    title: "OrderEdit",
    component: OrderEdit,
  },
  {
    path: "/order/create",
    title: "OrderCreate",
    component: OrderCreate,
  },
  { 
    path: "/order-create/admin",
    title: "OrderCreateForward",
    component: OrderCreateForward,
  },
  {
    path: "/order/:id/assign",
    title: "AssignCar",
    component: AssignCarList,
  },
  {
    path: "/driver-list",
    title: "DriverList",
    component: DriverList,
  },
  {
    path: "/driver/edit/:id",
    title: "DriverEdit",
    component: DriverEdit,
  },
  {
    path: "/driver/:id",
    title: "DriverDetails",
    component: DriverDetails,
  },
  {
    path: "/driver-create",
    title: "AgentCreate",
    component: DriverCreate,
  }, 
  {
    path:  "/companydetails",
    title: "CompanyDetails",
    component: CompanyDetails,
  },
  {
    path:  "/car-list",
    title: "CarList",
    component: CarList,
  },
  {
    path:  "/car-create",
    title: "CarCreate",
    component: CarCreate,
  },
  {
    path:  "/car/edit/:id",
    title: "CarEdit",
    component: CarEdit,
  },
  {
    path:  "/car/:id",
    title: "CarDetails",
    component: CarDetails,
  },
  {
    path:  "/user-list",
    title: "UserList",
    component: UserList,
  },
  {
    path:  "/company-list",
    title: "CompanyList",
    component: CompanyList,
  },
  {
    path:  "/company-edit/:id",
    title: "CompanyEdit",
    component: CompanyEdit,
  },
  {
    path:  "/brand-list",
    title: "BrandList",
    component: BrandList,
  },
  {
    path:  "/brand-create",
    title: "BrandCreate",
    component: BrandCreate,
  },
  {
    path:  "/brand/edit/:id",
    title: "BrandEdit",
    component: BrandEdit,
  },
  {
    path:  "/ban-list",
    title: "BanList",
    component: BanList,
  },
  {
    path:  "/ban-create",
    title: "BanCreate",
    component: BanCreate,
  },
  {
    path:  "/ban/edit/:id",
    title: "BanEdit",
    component: BanEdit,
  },
  {
    path:  "/cartype-list",
    title: "CarType",
    component: CarTypeList,
  },
  {
    path:  "/cartype-create",
    title: "CarTypeCreate",
    component: CarTypeCreate,
  },
  {
    path:  "/cartype/edit/:id",
    title: "CarTypeEdit",
    component: CarTypeEdit,
  },
  {
    path:  "/loadtype-list",
    title: "LoadType",
    component: LoadTypeList,
  },
  {
    path:  "/loadtype-create",
    title: "LoadTypeCreate",
    component: LoadTypeCreate,
  },
  {
    path:  "/loadtype/edit/:id",
    title: "loadTypeEdit",
    component: LoadTypeEdit,
  },
  {
    path:  "/paymenttype-list",
    title: "PaymentType",
    component: PaymentTypeList,
  },
  {
    path:  "/paymenttype-create",
    title: "PaymentTypeCreate",
    component: PaymentTypeCreate,
  },
  {
    path:  "/paymenttype/edit/:id",
    title: "PaymentTypeEdit",
    component: PaymentTypeEdit,
  },
  {
    path:  "/address-list",
    title: "Address List",
    component: AddressList,
  },
  {
    path:  "/address-create",
    title: "AddressCreate",
    component: AddressCreate,
  },
  {
    path:  "/address/edit/:id",
    title: "AddressEdit",
    component: AddressEdit,
  },
  {
    path:  "/blog-list",
    title: "Blog List",
    component: BlogList,
  },
  {
    path:  "blog-create",
    title: "BlogCreate",
    component: BlogCreate,
  },
  {
    path:  "/blog/edit/:id",
    title: "BlogEdit",
    component: BlogEdit,
  },
];


export default routes;