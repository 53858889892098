import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  carTypeDetailsData: null,
  carTypeDetailsLoading: false,
  carTypeDetailsError: null,
  carTypeUpdateSuccess: null,
  carTypeUpdateLoading: false,
  carTypeUpdateError: null,
  carTypeDeleteSuccess: null,
  carTypeDeleteLoading: false,
  carTypeDeleteError: null,
  carTypeCreateSuccess: null,
  carTypeCreateLoading: false,
  carTypeCreateError: null,
};

export const carTypeList = createAsyncThunk(
  "carType/carTypeList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/cartype/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const carTypeDetails = createAsyncThunk(
  "carType/carTypeDetails",
  async ({id}, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/cartype/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const updateCarType = createAsyncThunk(
  "carType/carTypeUpdate",
  async ({ id, updatedCarType }, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/cartype/${id}`,
        updatedCarType,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const carTypeCreate = createAsyncThunk(
  "carType/carTypeCreate",
  async ({ carType }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/cartype`,
        carType,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);



export const carTypeDelete = createAsyncThunk(
    "carType/carTypeDelete",
    async ({ id }, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.delete(
          `${apiConfig.API_URL}/api/cartype/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.log(rejectWithValue(error.response.data));
      }
    }
  );
  

const carTypeSlice = createSlice({
  name: "carType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(carTypeList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(carTypeList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(carTypeList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(carTypeDetails.pending, (state) => {
        state.carTypeDetailsLoading = true;
        state.carTypeDetailsError = null;
      })
      .addCase(carTypeDetails.fulfilled, (state, action) => {
        state.carTypeDetailsLoading = false;
        state.carTypeDetailsData = action.payload;
      })
      .addCase(carTypeDetails.rejected, (state, action) => {
        state.carTypeDetailsLoading = false;
        state.carTypeDetailsError = action.payload;
      })
      
      .addCase(updateCarType.pending, (state) => {
        state.carTypeUpdateLoading = true;
        state.carTypeUpdateError = null;
      })
      .addCase(updateCarType.fulfilled, (state, action) => {
        state.carTypeUpdateLoading = false;
        state.carTypeUpdateSuccess = action.payload.success;
      })
      .addCase(updateCarType.rejected, (state, action) => {
        state.carTypeUpdateLoading = false;
        state.carTypeUpdateError = action.payload;
      })
      .addCase(carTypeCreate.pending, (state) => {
        state.carTypeCreateLoading = true;
        state.carTypeCreateError = false;
      })
      .addCase(carTypeCreate.fulfilled, (state, action) => {
        state.carTypeCreateLoading = false;
        state.carTypeCreateSuccess = true;
      })
      .addCase(carTypeCreate.rejected, (state, action) => {
        state.carTypeCreateLoading = false;
        state.carTypeCreateError = true;
      })
      .addCase(carTypeDelete.pending, (state) => {
        state.carTypeDeleteLoading = true;
        state.carTypeDeleteError = false;
      })
      .addCase(carTypeDelete.fulfilled, (state, action) => {
        state.carTypeDeleteLoading = false;
        state.carTypeDeleteSuccess = true;
      })
      .addCase(carTypeDelete.rejected, (state, action) => {
        state.carTypeDeleteLoading = false;
        state.carTypeDeleteError = true;
      });
  },
});

// Export actions and reducer
export const carTypeActions = carTypeSlice.actions;
export default carTypeSlice.reducer;
