// ChoiceModal.jsx
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const ChoiceModal = ({ setIsOpenModal, isOpenModal }) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsOpenModal(false);
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenModal]);

  // Define the animation variants
  const modalVariants = {
    hidden: { opacity: 0, y: "-100%" },
    visible: { opacity: 1, y: "0%" },
    exit: { opacity: 0, y: "-100%" },
  };

  return (
    <AnimatePresence>
      {isOpenModal && (
        <motion.div
          className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={modalVariants}
        >
          <motion.div
            ref={modalRef}
            className="bg-white rounded-lg shadow-lg w-[95%] lg:w-[50%] mx-auto p-6 relative"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalVariants}
          >
            <div className="flex items-center justify-between">
              <h3 className="text-[24px]">Seçin</h3>
              <button
                className="text-gray-500"
                onClick={() => setIsOpenModal(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                >
                  <rect width="54" height="54" fill="#F1F1F1"></rect>
                  <path
                    d="M36.5994 17.3984L17.3994 36.5984"
                    stroke="#092256"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17.3994 17.3984L36.5994 36.5984"
                    stroke="#092256"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </button>
            </div>

            <div className="my-12 bg-white">
              <div className="w-[100%] px-4 py-4 rounded-[5px] cursor-pointer  my-2">
                <div class="border-[1px] border-solid border-slate-300">
                  <Link
                    to="/register/customer"
                    className="flex items-center justify-between p-4"
                  >
                    <div className="flex gap-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="56"
                        height="56"
                        viewBox="0 0 56 56"
                        fill="none"
                      >
                        <rect
                          width="56"
                          height="56"
                          rx="28"
                          fill="#F1F1F1"
                        ></rect>
                        <path
                          d="M28.76 41.1657C28.3628 41.319 27.9229 41.319 27.5257 41.1657C24.424 39.9495 21.7608 37.827 19.8832 35.0749C18.0057 32.3227 17.0009 29.0687 17 25.7371V20.7143C17 20.2596 17.1806 19.8236 17.5021 19.5021C17.8236 19.1806 18.2596 19 18.7143 19H37.5714C38.0261 19 38.4621 19.1806 38.7836 19.5021C39.1051 19.8236 39.2857 20.2596 39.2857 20.7143V25.72C39.2882 29.0545 38.2851 32.3123 36.4073 35.0678C34.5295 37.8233 31.8644 39.9485 28.76 41.1657Z"
                          stroke="#092256"
                          stroke-width="1.71429"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M21.0063 36.5377C21.7883 35.3613 22.8491 34.3966 24.0943 33.7296C25.3395 33.0626 26.7303 32.714 28.1429 32.7148C31.1206 32.7148 33.7435 34.2337 35.2812 36.5377"
                          stroke="#092256"
                          stroke-width="1.71429"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M28.1428 30.144C28.6493 30.144 29.1509 30.0442 29.6189 29.8504C30.0868 29.6565 30.512 29.3724 30.8702 29.0142C31.2284 28.6561 31.5125 28.2309 31.7063 27.7629C31.9002 27.2949 31.9999 26.7934 31.9999 26.2868C31.9999 25.7803 31.9002 25.2787 31.7063 24.8108C31.5125 24.3428 31.2284 23.9176 30.8702 23.5594C30.512 23.2012 30.0868 22.9171 29.6189 22.7233C29.1509 22.5295 28.6493 22.4297 28.1428 22.4297C27.1198 22.4297 26.1387 22.8361 25.4154 23.5594C24.692 24.2828 24.2856 25.2639 24.2856 26.2868C24.2856 27.3098 24.692 28.2909 25.4154 29.0142C26.1387 29.7376 27.1198 30.144 28.1428 30.144Z"
                          stroke="#092256"
                          stroke-width="1.71429"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <div className="text-[#0A2468] float-start">
                        <b>Yükverən olaraq qeydiyyatdan keç</b>
                        <br />
                        <span className="text-[12px]">
                        Böyük həcmli yükləriniz üçün uyğun daşıyıcılardan sürətli təklif alın.
                        </span>
                      </div>
                    </div>

                    <div class="hsp3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.11625 2.13405L15.7325 8.75024L1.25 8.75024C0.559999 8.75024 -9.7282e-07 9.31024 -1.03314e-06 10.0002C-1.09346e-06 10.6902 0.559998 11.2502 1.25 11.2502L15.7325 11.2502L9.11625 17.8664C8.6275 18.3552 8.6275 19.1464 9.11625 19.6339C9.605 20.1214 10.3962 20.1227 10.8837 19.6339L19.6338 10.884C19.7538 10.764 19.8438 10.6265 19.905 10.479C19.9688 10.3265 20 10.1627 20 10.0002C20 9.68024 19.8775 9.36024 19.6338 9.11649L10.8838 0.366559C10.395 -0.122186 9.60375 -0.122186 9.11625 0.366559C8.62875 0.855306 8.6275 1.64655 9.11625 2.13405Z"
                          fill="#092256"
                        ></path>
                      </svg>
                    </div>
                  </Link>
                </div>
                <div class="border-[1px] border-solid border-slate-300 mt-4">
                  <Link
                    to="/register/driver"
                    className="flex items-center justify-between p-4"
                  >
                    <div className="flex gap-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="56"
                        height="56"
                        viewBox="0 0 56 56"
                        fill="none"
                      >
                        <rect
                          width="56"
                          height="56"
                          rx="28"
                          fill="#F1F1F1"
                        ></rect>
                        <path
                          d="M30.7144 23.4297H35.8572C36.7665 23.4297 37.6386 23.7909 38.2816 24.4339C38.9246 25.0769 39.2858 25.9489 39.2858 26.8583V33.7154H36.7144"
                          stroke="#092256"
                          stroke-width="1.71429"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M18.7143 33.7143H17V21.7143C17 21.2596 17.1806 20.8236 17.5021 20.5021C17.8236 20.1806 18.2596 20 18.7143 20H29C29.4547 20 29.8907 20.1806 30.2122 20.5021C30.5337 20.8236 30.7143 21.2596 30.7143 21.7143V33.7143"
                          stroke="#092256"
                          stroke-width="1.71429"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M29.857 33.7148H25.5713"
                          stroke="#092256"
                          stroke-width="1.71429"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M33.7144 37.1445C32.9187 37.1445 32.1556 36.8285 31.593 36.2659C31.0304 35.7032 30.7144 34.9402 30.7144 34.1445C30.7144 33.3489 31.0304 32.5858 31.593 32.0232C32.1556 31.4606 32.9187 31.1445 33.7144 31.1445C34.51 31.1445 35.2731 31.4606 35.8357 32.0232C36.3983 32.5858 36.7144 33.3489 36.7144 34.1445C36.7144 34.9402 36.3983 35.7032 35.8357 36.2659C35.2731 36.8285 34.51 37.1445 33.7144 37.1445Z"
                          stroke="#092256"
                          stroke-width="1.71429"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M22.1431 37.1445C21.3474 37.1445 20.5844 36.8285 20.0217 36.2659C19.4591 35.7032 19.1431 34.9402 19.1431 34.1445C19.1431 33.3489 19.4591 32.5858 20.0217 32.0232C20.5844 31.4606 21.3474 31.1445 22.1431 31.1445C22.9387 31.1445 23.7018 31.4606 24.2644 32.0232C24.827 32.5858 25.1431 33.3489 25.1431 34.1445C25.1431 34.9402 24.827 35.7032 24.2644 36.2659C23.7018 36.8285 22.9387 37.1445 22.1431 37.1445Z"
                          stroke="#092256"
                          stroke-width="1.71429"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <div className="text-[#0A2468] float-start">
                        <b>Yük daşıyan olaraq qeydiyyatdan keç</b>
                        <br />
                        <span className="text-[12px]">
                        Mobil telefonunuzdan sürətli yük tapın.
                        </span>
                      </div>
                    </div>

                    <div class="hsp3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.11625 2.13405L15.7325 8.75024L1.25 8.75024C0.559999 8.75024 -9.7282e-07 9.31024 -1.03314e-06 10.0002C-1.09346e-06 10.6902 0.559998 11.2502 1.25 11.2502L15.7325 11.2502L9.11625 17.8664C8.6275 18.3552 8.6275 19.1464 9.11625 19.6339C9.605 20.1214 10.3962 20.1227 10.8837 19.6339L19.6338 10.884C19.7538 10.764 19.8438 10.6265 19.905 10.479C19.9688 10.3265 20 10.1627 20 10.0002C20 9.68024 19.8775 9.36024 19.6338 9.11649L10.8838 0.366559C10.395 -0.122186 9.60375 -0.122186 9.11625 0.366559C8.62875 0.855306 8.6275 1.64655 9.11625 2.13405Z"
                          fill="#092256"
                        ></path>
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ChoiceModal;
