import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  carDetailsData: null,
  carDetailsLoading: false,
  carDetailsError: null,
  carUpdateSuccess: null,
  carUpdateLoading: false,
  carUpdateError: null,
  carStatusUpdateSuccess: null,
  carStatusUpdateLoading: false,
  carStatusUpdateError: null,
  carDeleteSuccess: null,
  carDeleteLoading: false,
  carDeleteError: null,
  carCreateSuccess: null,
  carCreateLoading: false,
  carCreateError: null,
};

export const carList = createAsyncThunk(
  "car/carList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/cars/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);




export const carDetails = createAsyncThunk(
  "car/carDetails",
  async ({id},{ rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/cars/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );    
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);





export const carUpdate = createAsyncThunk(
  "car/carUpdate",
  async ({ updatedCar, id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/cars/${id}`,
        updatedCar,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);




export const carCreate = createAsyncThunk(
  "car/carCreate",
  async ({ createdCar }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/cars`,
        createdCar,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);


export const carStatusUpdate = createAsyncThunk(
  "car/carStatusUpdate",
  async ({ id,status }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/cars/${id}/${status}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);


export const carDelete = createAsyncThunk(
  "car/carDelete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${apiConfig.API_URL}/api/cars/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

const carSlice = createSlice({
  name: "car",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(carList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(carList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(carList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(carDetails.pending, (state) => {
        state.carDetailsLoading = true;
        state.carDetailsError = null;
      })
      .addCase(carDetails.fulfilled, (state, action) => {
        state.carDetailsLoading = false;
        state.carDetailsData = action.payload;
      })
      .addCase(carDetails.rejected, (state, action) => {
        state.carDetailsLoading = false;
        state.carDetailsError = action.payload;
      })
      
      .addCase(carUpdate.pending, (state) => {
        state.carUpdateLoading = true;
        state.carUpdateError = null;
      })
      .addCase(carUpdate.fulfilled, (state, action) => {
        state.carUpdateLoading = false;
        state.carUpdateSuccess = action.payload.success;
      })
      .addCase(carUpdate.rejected, (state, action) => {
        state.carUpdateLoading = false;
        state.carUpdateError = action.payload;
      })
      
      .addCase(carStatusUpdate.pending, (state) => {
        state.carStatusUpdateLoading = true;
        state.carStatusUpdateError = null;
      })
      .addCase(carStatusUpdate.fulfilled, (state, action) => {
        state.carStatusUpdateLoading = false;
        state.carStatusUpdateSuccess = action.payload.success;
      })
      .addCase(carStatusUpdate.rejected, (state, action) => {
        state.carStatusUpdateLoading = false;
        state.carStatusUpdateError = action.payload;
      })

      .addCase(carDelete.pending, (state) => {
        state.carDeleteLoading = true;
        state.carDeleteError = null;
      })
      .addCase(carDelete.fulfilled, (state, action) => {
        state.carDeleteLoading = false;
        state.carDeleteSuccess = action.payload.success;
      })
      .addCase(carDelete.rejected, (state, action) => {
        state.carDeleteLoading = false;
        state.carDeleteError = action.payload;
      })
      .addCase(carCreate.pending, (state) => {
        state.carCreateLoading = true;
        state.carCreateError = false;
      })
      .addCase(carCreate.fulfilled, (state, action) => {
        state.carCreateLoading = false;
        state.carCreateSuccess = action.payload.success;
      })
      .addCase(carCreate.rejected, (state, action) => {
        state.carCreateLoading = false;
        state.carCreateError = true;
      });
  },
});

// Export actions and reducer
export const carActions = carSlice.actions;
export default carSlice.reducer;
