  import React, { useState, useEffect } from "react";
  import { Link } from "react-router-dom";
  import { useTranslation, Trans } from "react-i18next";
  import { useNavigate } from "react-router-dom";
  const SubNavbar = () => {
    const { i18n } = useTranslation();
    const [isActive, setActive] = useState(false);
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const navigate = useNavigate();
    const handleFiyatHesaplaClick = (event) => {
      event.preventDefault();
  
      // Check if the current location is not the homepage
      if (window.location.pathname !== "/") {
        // Redirect to the homepage without scrolling
        navigate("/");
      } else {
        // Scroll to the element with id "packages" on the home page
        const element = document.getElementById("packages");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    return (
      <div className="hidden lg:flex lg:flex-row flex-col items-start lg:items-center gap-6 ">
        <Link to="/customer" activeClassName="active" className="list-none">
          <li
            className="text-[13px] text-[#092256]  leading-6 hover:text-indigo-500"
            onClick={scrollToTop}
          >
            <Trans i18nKey="description.navpart1">Hakkımızda</Trans>
          </li>
        </Link>

        <Link to="/driver" activeClassName="active" className="list-none">
          <li
            className="text-[13px] text-[#092256]  leading-6 hover:text-indigo-500"
            onClick={scrollToTop}
          >
            <Trans i18nKey="description.navpart2">Tıkla Gönder</Trans>
          </li>
        </Link>
        <Link to="/about" activeClassName="active" className="list-none">
          <li
            className="text-[13px] text-[#092256]  leading-6 hover:text-indigo-500"
            onClick={scrollToTop}
          >
            <Trans i18nKey="description.navpart3">Tıkla Gönder</Trans>
          </li>
        </Link>
   
        <Link to="/blogs" activeClassName="active" className="list-none">
          <li
            className="text-[13px] text-[#092256]  leading-6 hover:text-indigo-500"
            onClick={scrollToTop}
          >
            <Trans i18nKey="description.navpart4">Tıkla Gönder</Trans>
          </li>
        </Link>
        <Link to="/contact" activeClassName="active" className="list-none">
          <li
            className="text-[13px] text-[#092256] leading-6 hover:text-indigo-500"
            onClick={scrollToTop}
          >
            <Trans i18nKey="description.navpart5">Tıkla Gönder</Trans>
          </li>
        </Link>
      </div>
    );
  };

  export default SubNavbar;
