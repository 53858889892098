import React, { useState } from "react";
import Sidebar from "../components/Dashboard/Shared/Sidebar";
import { Outlet } from "react-router-dom";
import MobileHeader from "../components/Dashboard/Shared/MobileHeader";
import MobileSidebar from "../components/Dashboard/Shared/MobileSidebar";
const Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="w-[100%] h-screen w-screen  flex flex-row relative bg-[#fafafa]">
      <Sidebar />
      <div className="lg:hidden bg-green-400">
      <MobileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      </div>
      <div className="flex flex-col flex-1 gap-2 overflow-x-auto">
        <MobileHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
        <div className="p-4  overflow-x-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
