import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import logo from "../../assets/logo/logoSVG.svg";
import { NavLink, Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import {useSelector, useDispatch} from "react-redux";
import swal from "sweetalert";
import { loginUser } from "../../redux/slices/authSlice";
import ActionButton from "../../partials/ActionButton";

const Login = () => {
  const { i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [pemail, setPEmail] = useState("E-poçt ünvanı");
  const [ppassword, setPPassword] = useState("Şifre");

  useEffect(() => {
    if (i18n.language !== "en") {
      setPEmail("E-poçt ünvanı");
      setPPassword("Şifrə");
    } else {
      setPEmail("Email Address");
      setPPassword("Password");
    }
  }, [i18n.language]);

  
  const dispatch = useDispatch();

  const { loginSuccess, loginLoading, loginError } =
    useSelector((state) => state.auth);



  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const userData = { email, password };
      await dispatch(loginUser({userData}));
    } catch (error) {
      swal("Error!", "Please try again!", "error");
      console.error(error);
    }
  };

  return (
    <div className="w-[90%] lg:w-[50%] mx-auto  overflow-hidden">
      <div className="flex flex-col justify-center items-center mx-auto my-4">
        <Link to="/">
          <img className="max-w-[200px]" src={logo} alt="prop" />
        </Link>
        <h1 className="font-black text-[24px] lg:text-[32px] pt-[8px]">
          <Trans i18nKey="description.loginpart1">Üye Girişi Yapın</Trans>
        </h1>

        <div className="flex flex-row pt-[8px]">
          <p>
            <Trans i18nKey="description.loginpart2">veya</Trans>
          </p>
          <NavLink className="pl-[5px] text-indigo-500" to="/register/customer">
            <Trans i18nKey="description.loginpart3">
              Yeni üyeliik oluşturun
            </Trans>
          </NavLink>
        </div>
        <form onSubmit={handleLogin} className="w-[100%]">
          <div className="flex flex-col m-[5px] pt-[25px] w-[100%] mx-auto">
            <label className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2">
              <Trans i18nKey="description.registerpart9">E-posta:</Trans>
            </label>
            <input
              className="w-[100%] text-[14px] px-[20px] py-[14px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
              type="email"
              placeholder={pemail}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label className="text-[#000] opacity-[0.7] text-[14px] font-bold py-2">
              <Trans i18nKey="description.registerpart11">E-posta:</Trans>
            </label>
            <div className="bg-[#fff] relative flex justify-between text-[14px] px-[20px] py-[14px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]">
              <input
                className="w-[100%] border-none focus-none outline-none h-[100%]"
                type={showPassword ? "text" : "password"}
                placeholder={ppassword}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <FaEyeSlash className="text-[20px] text-indigo-500" />
                ) : (
                  <FaEye className="text-[20px] text-indigo-500" />
                )}
              </div>
            </div>
            <div className="flex flex-col lg:flex-row  lg:items-center justify-between m-[5px] pt-[10px]">
              <label className="label1 flex items-center gap-2">
                <input type="checkbox" className="w-[20px] h-[20px]" />
                <Trans i18nKey="description.loginpart4">Beni hatırla</Trans>
              </label>
              <NavLink
                className="text-indigo-500 lg:pt-0 pt-2"
                to="/resetpassword"
              >
                <Trans i18nKey="description.loginpart5">Şifremi unuttum</Trans>
              </NavLink>
            </div>

            
            <ActionButton
              content="loginpart6"
              success={loginSuccess}
              loading={loginLoading}
              error={loginError}
              path="/dashboard"
              message="Daxil oldunuz"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
