import React from "react";

const Cards = ({ card }) => {
  return (
    <div className="shadow-md px-4 py-6 rounded-[5px] bg-white">
      <div>
       {card?.logo}
      </div>
      <h3 className="text-[16px] pt-[30px]"><b>{card?.title}</b></h3>
      <p className="text-[13px] pt-[15px]">{card?.value}</p>
    </div>
  );
};

export default Cards;
