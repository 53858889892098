import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/helperFunctions";
const BlogCard = ({ blog }) => {
  return (
    <figure>
      <Link to={`/blog/${blog?._id}`}>
        <div className="">
          <img
            alt=""
            className="w-[100%] rounded-[8px]"
            src="https://www.kamion.co/wp-content/uploads/2024/06/shutterstock_195043625.webp"
          />
        </div>
      </Link>
      <figcaption>
        <a
          href="https://www.kamion.co/blog/tir-ve-kamyonlar-icin-yasak-yol-saatleri-nedir"
          className="text-[15px] text-[#092256] mt-8"
        >
          {blog?.title}
        </a>
        <div className="text-[14px] mt-[8px]">
          {formatDate(blog?.createdAt)}
        </div>
      </figcaption>
    </figure>
  );
};

export default BlogCard;
