import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  blogDetailsData: null,
  blogDetailsLoading: false,
  blogDetailsError: null,
  blogUpdateSuccess: null,
  blogUpdateLoading: false,
  blogUpdateError: null,
  blogDeleteSuccess: null,
  blogDeleteLoading: false,
  blogDeleteError: null,
  blogCreateSuccess: null,
  blogCreateLoading: false,
  blogCreateError: null,
};

export const blogList = createAsyncThunk(
  "blog/blogList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/blog/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const blogDetails = createAsyncThunk(
  "blog/blogDetails",
  async ({id}, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${apiConfig.API_URL}/api/blog/${id}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const updateblog = createAsyncThunk(
  "blog/blogUpdate",
  async ({ id, updatedblog }, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/blog/${id}`,
        updatedblog,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const blogCreate = createAsyncThunk(
  "blog/blogCreate",
  async ({ blog }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/blog`,
        blog,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);



export const blogDelete = createAsyncThunk(
    "blog/blogDelete",
    async ({ id }, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.delete(
          `${apiConfig.API_URL}/api/blog/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.log(rejectWithValue(error.response.data));
      }
    }
  );
  

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(blogList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(blogList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(blogList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(blogDetails.pending, (state) => {
        state.blogDetailsLoading = true;
        state.blogDetailsError = null;
      })
      .addCase(blogDetails.fulfilled, (state, action) => {
        state.blogDetailsLoading = false;
        state.blogDetailsData = action.payload;
      })
      .addCase(blogDetails.rejected, (state, action) => {
        state.blogDetailsLoading = false;
        state.blogDetailsError = action.payload;
      })
      
      .addCase(updateblog.pending, (state) => {
        state.blogUpdateLoading = true;
        state.blogUpdateError = null;
      })
      .addCase(updateblog.fulfilled, (state, action) => {
        state.blogUpdateLoading = false;
        state.blogUpdateSuccess = action.payload.success;
      })
      .addCase(updateblog.rejected, (state, action) => {
        state.blogUpdateLoading = false;
        state.blogUpdateError = action.payload;
      })
      .addCase(blogCreate.pending, (state) => {
        state.blogCreateLoading = true;
        state.blogCreateError = false;
      })
      .addCase(blogCreate.fulfilled, (state, action) => {
        state.blogCreateLoading = false;
        state.blogCreateSuccess = true;
      })
      .addCase(blogCreate.rejected, (state, action) => {
        state.blogCreateLoading = false;
        state.blogCreateError = true;
      })
      .addCase(blogDelete.pending, (state) => {
        state.blogDeleteLoading = true;
        state.blogDeleteError = false;
      })
      .addCase(blogDelete.fulfilled, (state, action) => {
        state.blogDeleteLoading = false;
        state.blogDeleteSuccess = true;
      })
      .addCase(blogDelete.rejected, (state, action) => {
        state.blogDeleteLoading = false;
        state.blogDeleteError = true;
      });
  },
});

// Export actions and reducer
export const blogActions = blogSlice.actions;
export default blogSlice.reducer;
