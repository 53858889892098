import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  contactCreateSuccess: null,
  contactCreateLoading: false,
  contactCreateError: null,
};


export const contactCreate = createAsyncThunk(
  "contact/contactCreate",
  async ({ data }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/contact`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

 
  

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contactCreate.pending, (state) => {
        state.contactCreateLoading = true;
        state.contactCreateError = false;
      })
      .addCase(contactCreate.fulfilled, (state, action) => {
        state.contactCreateLoading = false;
        state.contactCreateSuccess = true;
      })
      .addCase(contactCreate.rejected, (state, action) => {
        state.contactCreateLoading = false;
        state.contactCreateError = true;
      })
  },
});

// Export actions and reducer
export const contactActions = contactSlice.actions;
export default contactSlice.reducer;
