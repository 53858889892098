import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { detailsUser } from "../../redux/slices/userSlice";
import { Squash as Hamburger } from "hamburger-react";
import SubNavbar from "./SubNavbar";
import logo from "../../assets/logo/logoSVG.svg";
import MobileSubNavbar from "./MobileSubNavbar";
const Navbar = ({ setIsOpenModal, isOpenModal }) => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleDropdownChange = (event) => {
    const selectedLanguage = event.target.value;
    setSelectedLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  const availableLanguages = {
    en: "EN",
    tr: "AZ",
  };

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(detailsUser());
  }, [dispatch]);

  const [click, setClick] = useState(false);

  return (
    <>
      <nav className="sticky top-0 z-50 bg-[#ffffff] text-[]">
        <div className="flex items-center justify-between w-[95%] lg:w-[80%] mx-auto">
          <div className="flex items-center gap-6">
            <Link exact to="/" className="flex items-center justify-center">
              <div class="logo">
                <a href="https://vectusport.com" title="anasayfa">
                  <img className="w-[140px] h-[120px]" src={logo} alt="logo" />
                </a>
              </div>
            </Link>
            <SubNavbar />
          </div>
          {/* Desktop Navigation */}
          <ul className={`hidden lg:flex justify-between items-center gap-4`}>
            {user ? (
              <li className="bg-indigo-500 rounded-[5px] text-white border-[1px] border-slate-400 text-[#092256] px-4 py-2">
                <Link to="/dashboard">
                  {" "}
                  <button className="text-white">
                    <Trans i18nKey="">
                      {user?.name} {user?.surname}
                    </Trans>
                  </button>{" "}
                </Link>
              </li>
            ) : (
              <>
                <li>
                  <Link to="/login" className="nav-links text-[14px]">
                    <Trans i18nKey="description.navpart6">Daxil ol</Trans>
                  </Link>
                </li>
                <li
                  onClick={() => setIsOpenModal(!isOpenModal)}
                  className="cursor-pointer nav-links text-[14px] bg-[#0085FF] rounded-[100px] text-white px-[24px] py-[12px]"
                >
                  <Trans i18nKey="description.navpart7">Qeydiyyatdan keç</Trans>
                </li>
              </>
            )}

            <div className="lg:block hidden">
              <div className="relative">
                <select
                  value={selectedLanguage}
                  onChange={(e) => handleDropdownChange(e)}
                  className="cursor-pointer flex border-[1px] border-solid border-slate-300 bg-transparent text-black px-4 py-[10px] text-[12px] rounded-md"
                >
                  {Object.keys(availableLanguages).map((languageCode) => (
                    <option
                      key={languageCode}
                      value={languageCode}
                      className="cursor-pointer"
                    >
                      {availableLanguages[languageCode]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </ul>

          {/* Mobile Sidebar */}
          <div className="lg:hidden block">
            <Hamburger toggled={click} toggle={setClick} />
            {click && (
              <ul className="fixed top-0 left-0 w-[80%] h-full bg-white sidebarshadow p-4 flex flex-col z-50 ">
                <Link exact to="/" className="">
                  <img src={logo} alt="" className="w-[140px] h-[120px]" />
                </Link>

                {user ? (
                  <Link to="/dashboard">
                    <li className="bg-indigo-500 rounded-[10px] border-[1px] border-slate-400 text-white px-4 py-2">
                      {" "}
                      <Trans i18nKey="">
                        {user?.name} {user?.surname}
                      </Trans>
                    </li>
                  </Link>
                ) : (
                  <>
                    <li className="px-4 py-2 rounded-[10px] border-[1px] border-slate-400">
                      <Link to="/login" className="nav-links">
                        <Trans i18nKey="description.navpart6">
                          {" "}
                          Giriş yap{" "}
                        </Trans>
                      </Link>
                    </li>
                    <li
                      onClick={() => setIsOpenModal(!isOpenModal)}
                      className="bg-indigo-500 rounded-[10px] border-[1px] border-slate-400 text-white px-4 py-2 mt-2"
                    >
                      <Trans i18nKey="description.navpart7">Register</Trans>
                    </li>
                  </>
                )}
                <MobileSubNavbar />
                <li className="nav-item dropdown-item  relative">
                  <div className="">
                    <select
                      value={selectedLanguage}
                      onChange={(e) => handleDropdownChange(e)}
                      className="cursor-pointer flex border-[1px] border-slate-300 rounded-[5px] px-4 py-[4px] text-[14px] mt-2 outline-indigo-400"
                    >
                      {Object.keys(availableLanguages).map((languageCode) => (
                        <option
                          key={languageCode}
                          value={languageCode}
                          className="cursor-pointer border-[1px] border-slate-300"
                        >
                          {availableLanguages[languageCode]}
                        </option>
                      ))}
                    </select>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
      <div className="w-[100%] h-[1px] bg-slate-100"></div>
    </>
  );
};

export default Navbar;
