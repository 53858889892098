import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  loadDetailsData: null,
  loadDetailsLoading: false,
  loadDetailsError: null,
  loadUpdateSuccess: null,
  loadUpdateLoading: false,
  loadUpdateError: null,
  loadDeleteSuccess: null,
  loadDeleteLoading: false,
  loadDeleteError: null,
  loadCreateSuccess: null,
  loadCreateLoading: false,
  loadCreateError: null,
};

export const loadList = createAsyncThunk(
  "load/loadList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/load/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const loadDetails = createAsyncThunk(
  "load/loadDetails",
  async ({id}, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/load/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const updateload = createAsyncThunk(
  "load/loadUpdate",
  async ({ id, updatedload }, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/load/${id}`,
        updatedload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const loadCreate = createAsyncThunk(
  "load/loadCreate",
  async ({ load }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/load`,
        load,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);



export const loadDelete = createAsyncThunk(
    "load/loadDelete",
    async ({ id }, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.delete(
          `${apiConfig.API_URL}/api/load/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.log(rejectWithValue(error.response.data));
      }
    }
  );
  

const loadSlice = createSlice({
  name: "load",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(loadList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(loadDetails.pending, (state) => {
        state.loadDetailsLoading = true;
        state.loadDetailsError = null;
      })
      .addCase(loadDetails.fulfilled, (state, action) => {
        state.loadDetailsLoading = false;
        state.loadDetailsData = action.payload;
      })
      .addCase(loadDetails.rejected, (state, action) => {
        state.loadDetailsLoading = false;
        state.loadDetailsError = action.payload;
      })
      
      .addCase(updateload.pending, (state) => {
        state.loadUpdateLoading = true;
        state.loadUpdateError = null;
      })
      .addCase(updateload.fulfilled, (state, action) => {
        state.loadUpdateLoading = false;
        state.loadUpdateSuccess = action.payload.success;
      })
      .addCase(updateload.rejected, (state, action) => {
        state.loadUpdateLoading = false;
        state.loadUpdateError = action.payload;
      })
      .addCase(loadCreate.pending, (state) => {
        state.loadCreateLoading = true;
        state.loadCreateError = false;
      })
      .addCase(loadCreate.fulfilled, (state, action) => {
        state.loadCreateLoading = false;
        state.loadCreateSuccess = true;
      })
      .addCase(loadCreate.rejected, (state, action) => {
        state.loadCreateLoading = false;
        state.loadCreateError = true;
      })
      .addCase(loadDelete.pending, (state) => {
        state.loadDeleteLoading = true;
        state.loadDeleteError = false;
      })
      .addCase(loadDelete.fulfilled, (state, action) => {
        state.loadDeleteLoading = false;
        state.loadDeleteSuccess = true;
      })
      .addCase(loadDelete.rejected, (state, action) => {
        state.loadDeleteLoading = false;
        state.loadDeleteError = true;
      });
  },
});

// Export actions and reducer
export const loadActions = loadSlice.actions;
export default loadSlice.reducer;
