import React from "react";
import { Link } from "react-router-dom";
import shipper from "../../assets/images/shipper.png";
import driver from "../../assets/images/driverapp.png";
const Integration = () => {
  return (
    <>
      <div className="w-[80%] mx-auto flex lg:flex-row flex-col py-8 items-center gap-12 justify-between">
        <h2 className="text-[16px] lg:text-[24px] lg:text-left text-center  text-[#092256]">
        Yeni texnologiya ilə rahatlıqla daşımalarınızı həyata keçirə bilərsiniz
        </h2>
        <Link className="bg-[#0085FF] text-white text-center rounded-[100px] px-[12px]  py-[12px] lg:py-[24px]">
        Yük daşımanızı bizə etibar edin
        </Link>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 w-[80%] mx-auto py-8">
        <div className="w-[100%] bg-[#ECF5FF] border-[4px] border-solid border-[#ECF5FF] rounded-[10px] px-[16px] py-[32px]">
          <figcaption>
            <h3 className="text-center text-[#0085FF] text-[20px]">
            Sifarişçi paneli
            </h3>
            <p className="text-center text-[24px]">
            Yükünüz var amma daşıyıcı axtarırsız?
            </p>
          </figcaption>
          <figure className="p-8">
            <img
              title="shipper panel"
              width="auto"
              height="auto"
              alt=""
              src={shipper}
            />
          </figure>
        </div>
        <div className="w-[100%] bg-[#ECF5FF] border-[4px] border-solid border-[#ECF5FF] rounded-[10px] px-[16px] py-[32px]">
          <figcaption>
            <h3 className="text-center text-[#0085FF] text-[20px]">
            Mobil Tətbiq(çox yaxında)
            </h3>
            <p className="text-center text-[24px]">
            Yük axtarırsınız?
            </p>
          </figcaption>
          <figure className="p-8">
            <img
              title="kamion app"
              width="auto"
              height="auto"
              alt=""
              src={driver}
            />
          </figure>
        </div>
      </div>
    </>
  );
};

export default Integration;
