import React, {useState} from "react";
import Footer from "../../components/Home/Footer";
import Integration from "../../components/Home/Integration";
import Form from "../../components/Home/Form";
import Navbar from "../../components/Home/NavBar";
import Hero from "../../components/Home/Hero";
import Features from "../../components/Home/Features";
import Blogs from "../../components/Home/Blogs";
import ChoiceModal from "../../components/Home/ChoiceModal";
import { useTranslation, Trans } from "react-i18next";

const Home = () => {
  const { i18n } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <div className="">
      <Navbar setIsOpenModal={setIsOpenModal} isOpenModal={isOpenModal} />
        <div>
          <div className="heropage">
            <Hero />
          </div>

          <div>
            <Integration />
          </div>

          <div className="bg-[#f5f5f5]">
            <Features />
          </div>

          <div>
            <Blogs />
          </div>

          <div className="bg-[#092256]">
            <Footer
            />
          </div>
        </div>
      </div>
      <ChoiceModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </>
  );
};

export default Home;
