import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import logo from "../../assets/logo/logoSVG.svg";
import Navbar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
import { forgotPassword } from "../../redux/slices/userSlice";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import ActionButton from "../../partials/ActionButton";
const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [emailholder, setEmailholder] = useState("");

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { forgotPasswordSuccess, forgotPasswordLoading, forgotPasswordError } =
    useSelector((state) => state.user);

  useEffect(() => {
    if (i18n.language == "en") {
      setEmailholder("Enter your email");
    } else {
      setEmailholder("E-poçt daxil edin");
    }
  }, [i18n.language]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const emailData = {
      email,
    };

    try {
      await dispatch(forgotPassword({ emailData }));
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Try again later!",
        icon: "error",
      });
    }
  };
  return (
    <>
      <Navbar />
      <div className="w-[95%] lg:w-[80%] mx-auto mt-4">
        <div className="w-[50%] flex flex-col justify-center items-center mx-auto">
          <img className="w-[200px]" src={logo} alt="Logo" />
          <h1 className="text-[24px] my-4">
            <Trans i18nKey="description.Rpoart0">Reset Password</Trans>
          </h1>
          <form onSubmit={handleResetPassword} className="w-[100%]">
            <div className="w-[100%]">
              <label className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2">
                <Trans i18nKey="description.registerpart9">E-posta:</Trans>
              </label>
              <input
                className="w-[100%] text-[14px] px-[20px] py-[14px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
                type="email"
                placeholder={emailholder}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <ActionButton
              content="createshipmentpart15"
              success={forgotPasswordSuccess}
              loading={forgotPasswordLoading}
              error={forgotPasswordError}
              path="/login"
              message="Password succesfully changed"
            />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;
