import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import Navbar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
import swal from "sweetalert";
import {useSelector, useDispatch} from "react-redux";
import { registerUser } from "../../redux/slices/authSlice";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ActionButton from "../../partials/ActionButton";

const Register = () => {
  const { i18n } = useTranslation();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setpasswordAgain] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");


  const dispatch = useDispatch();

  const { registerSuccess, registerLoading, registerError } =
    useSelector((state) => state.auth);



  useEffect(() => {
    const typeURL = window.location.pathname.split("/")[2];
    if (typeURL === "customer") {
      setType("Customer");
    } else if (typeURL === "driver") {
      setType("Driver");
    }
  }, []);
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== passwordAgain) {
      toast.error("Passwords do not match");
      return;
    }

    const passwordStrengthRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordStrengthRegex.test(password)) {
      toast.error(
        "Password must be at least 8 characters long and contain a mix of uppercase and lowercase letters and numbers."
      );
      return;
    }

    try {
      const userData = {
        name,
        surname,
        email,
        password,
        phoneNumber: phone,
        userType: type,
      };
      await dispatch(registerUser({userData}));
    } catch (error) {
      swal(
        "Error!",
        error.message
          ? "Error occured"
          : "Please try again!",
        "error"
      );
      console.error(error);
    }
  };

  return (
    <div className="">
      <Navbar />
      <div className="flex  w-[85%] lg:w-[80%] mx-auto my-12  flex-col lg:flex-row justify-between  items-center  gap-8  py-16 py-8 ">
        <div className="w-[100%] lg:w-[60%]  mx-auto">
          <h2 className="font-extrabold text-center text-[24px] lg:text-[32px]">
            <Trans i18nKey="description.registerpart4">Kayıt Olun</Trans>
          </h2>

          <h3 className="text-[20px] text-center pt-[10px] opacity-[0.7]">
            <Trans i18nKey="description.registerpart3">
              KAVVAY ile yurtdışı gönderileriniz daha hızlı
            </Trans>
          </h3>
          <div className="text-center flex items-center justify-center gap-2">
            <p>
              <Trans i18nKey="description.registerpart5">veya</Trans>
            </p>
            <NavLink className="pl-[5px] text-[#0000ff]" to="/login">
              <Trans i18nKey="description.registerpart6">Giriş Yapın</Trans>
            </NavLink>
          </div>

          <form onSubmit={handleRegister}>
            <div className="pt-2">
              <label
                htmlFor="firstName"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold py-2"
              >
                <Trans i18nKey="description.registerpart7">İsim</Trans>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder={i18n.t("description.orderinputplaceholder1")}
                value={name}
                className="w-[100%] text-[14px] px-[20px] py-[10px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="pt-2">
              <label
                htmlFor="lastName"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2"
              >
                <Trans i18nKey="description.registerpart8">Soyisim:</Trans>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder={i18n.t("description.orderinputplaceholder2")}
                value={surname}
                className="w-[100%] text-[14px] px-[20px] py-[10px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
                onChange={(e) => setSurname(e.target.value)}
                required
              />
            </div>
            <div className="pt-2">
              <label
                htmlFor="email"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2"
              >
                <Trans i18nKey="description.registerpart9">E-posta:</Trans>
              </label>
              <input
                type="email"
                value={email}
                placeholder={i18n.t("description.orderinputplaceholder3")}
                className="w-[100%] text-[14px] px-[20px] py-[10px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="pt-2">
              <label
                htmlFor="telephone"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2"
              >
                <Trans i18nKey="description.registerpart10"> Telefon:</Trans>
              </label>
              <PhoneInput
                international
                defaultCountry="AZ"
                onChange={setPhone}
                id="phone"
                name="phone"
                className="numinput"
                required
              />
            </div>

            <div className="pt-2">
              <label
                htmlFor="password"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2"
              >
                <Trans i18nKey="description.registerpart11">Parola:</Trans>
              </label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder={i18n.t("description.registerinputplaceholder1")}
                value={password}
                className="w-[100%] text-[14px] px-[20px] py-[10px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="pt-2">
              <label
                htmlFor="confirmPassword"
                className="text-[#000] opacity-[0.7] text-[14px] font-bold pb-2"
              >
                <Trans i18nKey="description.registerpart12">
                  Parola Tekrar:
                </Trans>
              </label>
              <input
                type="password"
                id="confirmPassword"
                placeholder={i18n.t("description.registerinputplaceholder2")}
                name="confirmPassword"
                className="w-[100%] text-[14px] px-[20px] py-[10px] sidebarshadow border-[1px] border-[#f1eeee] outline-none rounded-[5px]"
                value={passwordAgain}
                onChange={(e) => setpasswordAgain(e.target.value)}
                required
              />
            </div>

            <div className="flex flex-row justify-between m-[5px] pt-[10px] gap-2">
              <label className="gap-2 cursor-pointer">
                <input
                  required
                  type="checkbox"
                  className="w-[20px] h-[20px] cursor-pointer"
                />
              </label>
              <div>
                <div className="flex flex-row">
                  <p>
                    <NavLink  className="text-[#0000ff]">
                      <Trans i18nKey="description.registerpart13F">
                        Oxudum və qəbul edirəm
                      </Trans>
                    </NavLink>
                   
                  
                  
                  </p>
                </div>
              </div>
            </div>
          
          
          
            <ActionButton
              content="registerpart15"
              success={registerSuccess}
              loading={registerLoading}
              error={registerError}
              path="/login"
              message="Successfully registered"
            />
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
