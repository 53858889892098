import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  user: null,
  loading: false,
  error: null,
  userUpdate: null,
  userUpdateLoading: false,
  userUpdateError: null,
  userChangePassword: null,
  userChangePasswordLoading: false,
  userChangePasswordError: null,
  userListData: null,
  userListLoading: false,
  userListError: null,
  forgotPasswordSuccess: null,
  forgotPasswordLoading: false,
  forgotPasswordError: null
};

export const detailsUser = createAsyncThunk(
  `/user/details`,
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  `/user/update`,
  async ({ updatedUser }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/profile/update`,
        updatedUser,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);


export const updateUserPassword = createAsyncThunk(
  `/user/updatepassword`,
  async ({ updatedUserPassword }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/profile/change-password`,
        updatedUserPassword,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // localStorage.setItem("token", response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);


// Write forgotpassword thunk that accept email
  
export const forgotPassword = createAsyncThunk(
  `/user/forgotpassword`,
  async ({ emailData }) => {
    try {
      const response = await axios.post(
        `${apiConfig.API_URL}/api/users/forgot-password`,
        emailData
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);



export const userList = createAsyncThunk(
  `/user/fetchUserList`,
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/users/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);


// Create the auth slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(detailsUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(detailsUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(detailsUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(userList.pending, (state) => {
        state.userListLoading = true;
        state.userListError = null;
      })
      .addCase(userList.fulfilled, (state, action) => {
        state.userListLoading = false;
        state.userListData = action.payload;
      })
      .addCase(userList.rejected, (state, action) => {
        state.userListLoading = false;
        state.userListError = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.userUpdateLoading = true;
        state.userUpdateError = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.userUpdateLoading = false;
        state.userUpdate = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.userUpdateLoading = false;
        state.userUpdateError = action.payload;
      })
      .addCase(updateUserPassword.pending, (state) => {
        state.userChangePasswordLoading = true;
        state.userChangePasswordError = null;
      })
      .addCase(updateUserPassword.fulfilled, (state, action) => {
        state.userChangePasswordLoading = false;
        state.userChangePassword = action.payload;
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.userChangePasswordLoading = false;
        state.userChangePasswordError = null;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordLoading = true;
        state.forgotPasswordError = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordLoading = false;
        state.forgotPasswordSuccess = action.payload.success;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPasswordLoading = false;
        state.forgotPasswordError = null;
      })
  },
});

// Export actions and reducer
export const userActions = userSlice.actions;
export default userSlice.reducer;
