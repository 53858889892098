import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  banDetailsData: null,
  banDetailsLoading: false,
  banDetailsError: null,
  banUpdateSuccess: null,
  banUpdateLoading: false,
  banUpdateError: null,
  banDeleteSuccess: null,
  banDeleteLoading: false,
  banDeleteError: null,
  banCreateSuccess: null,
  banCreateLoading: false,
  banCreateError: null,
};

export const banList = createAsyncThunk(
  "ban/banList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/bans/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const banDetails = createAsyncThunk(
  "ban/banDetails",
  async ({id}, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/bans/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const updateBan = createAsyncThunk(
  "ban/banUpdate",
  async ({ id, updatedBan }, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/bans/${id}`,
        updatedBan,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const banCreate = createAsyncThunk(
  "ban/banCreate",
  async ({ ban }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/bans`,
        ban,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);



export const banDelete = createAsyncThunk(
    "ban/banDelete",
    async ({ id }, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.delete(
          `${apiConfig.API_URL}/api/bans/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.log(rejectWithValue(error.response.data));
      }
    }
  );
  

const banSlice = createSlice({
  name: "ban",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(banList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(banList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(banList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(banDetails.pending, (state) => {
        state.banDetailsLoading = true;
        state.banDetailsError = null;
      })
      .addCase(banDetails.fulfilled, (state, action) => {
        state.banDetailsLoading = false;
        state.banDetailsData = action.payload;
      })
      .addCase(banDetails.rejected, (state, action) => {
        state.banDetailsLoading = false;
        state.banDetailsError = action.payload;
      })
      
      .addCase(updateBan.pending, (state) => {
        state.banUpdateLoading = true;
        state.banUpdateError = null;
      })
      .addCase(updateBan.fulfilled, (state, action) => {
        state.banUpdateLoading = false;
        state.banUpdateSuccess = action.payload.success;
      })
      .addCase(updateBan.rejected, (state, action) => {
        state.banUpdateLoading = false;
        state.banUpdateError = action.payload;
      })
      .addCase(banCreate.pending, (state) => {
        state.banCreateLoading = true;
        state.banCreateError = false;
      })
      .addCase(banCreate.fulfilled, (state, action) => {
        state.banCreateLoading = false;
        state.banCreateSuccess = true;
      })
      .addCase(banCreate.rejected, (state, action) => {
        state.banCreateLoading = false;
        state.banCreateError = true;
      })
      .addCase(banDelete.pending, (state) => {
        state.banDeleteLoading = true;
        state.banDeleteError = false;
      })
      .addCase(banDelete.fulfilled, (state, action) => {
        state.banDeleteLoading = false;
        state.banDeleteSuccess = true;
      })
      .addCase(banDelete.rejected, (state, action) => {
        state.banDeleteLoading = false;
        state.banDeleteError = true;
      });
  },
});

// Export actions and reducer
export const banActions = banSlice.actions;
export default banSlice.reducer;
