import React from "react";
import Navbar from "../../components/Home/NavBar";
import Footer from "../../components/Home/Footer";
import { Link } from "react-router-dom";
import Questions from "../../components/Home/Questions";
import Form from "../../components/Home/Form";
import shipperImage from "../../assets/images/shipper.png";

const DriverScreen = () => {
  const advantages = [
    {
      id: 1,
      title: "Pulsuz Üzvlük",
      content:
        "Mobil tətbiqi pulsuz yükləyin, maşınınıza uyğun yükü seçin və yola çıxın!",
      imgUrl: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="53"
          height="52"
          viewBox="0 0 53 52"
          fill="none"
        >
          <path
            d="M36.8902 15.4064C36.6241 14.6519 36.1304 13.9986 35.4773 13.5364C34.8242 13.0743 34.0438 12.8261 33.2438 12.8262H30.2462C29.3977 12.8307 28.5806 13.1474 27.9507 13.716C27.3209 14.2845 26.9225 15.0651 26.8315 15.9087C26.7404 16.7522 26.9632 17.5998 27.4573 18.2896C27.9513 18.9794 28.682 19.4632 29.51 19.6486L34.071 20.6465C35.0017 20.8521 35.8238 21.3938 36.3799 22.168C36.9359 22.9422 37.1867 23.8943 37.0843 24.8419C36.9818 25.7895 36.5332 26.666 35.8246 27.3034C35.1159 27.9408 34.1969 28.2943 33.2438 28.2962H30.6635C29.8636 28.2956 29.0835 28.0473 28.4305 27.5852C27.7775 27.1231 27.2837 26.4701 27.0171 25.7159"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M31.9541 12.8235V8.95312"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M31.9541 32.1614V28.291"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M13.3975 20.5587C13.3975 25.4807 15.3527 30.2012 18.8332 33.6816C22.3136 37.162 27.0341 39.1173 31.9561 39.1173C36.8782 39.1173 41.5987 37.162 45.0791 33.6816C48.5595 30.2012 50.5148 25.4807 50.5148 20.5587C50.5148 15.6366 48.5595 10.9161 45.0791 7.43571C41.5987 3.95528 36.8782 2 31.9561 2C27.0341 2 22.3136 3.95528 18.8332 7.43571C15.3527 10.9161 13.3975 15.6366 13.3975 20.5587Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M4.24748 22.5938C2.37244 26.0482 1.64278 30.0087 2.1637 33.9046C2.68463 37.8004 4.42922 41.4301 7.14598 44.2705C9.86273 47.1109 13.4113 49.0153 17.2801 49.709C21.1488 50.4027 25.138 49.8499 28.6724 48.1304"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      id: 2,
      title: "Sürətli Yük Tap və Ödəniş Al",
      content:
        "Yük ödənişləriniz rahat və təhlükəsiz şəkildə, komissiyasız və vaxt itirmədən hesabınıza yatırılır. Pulunuzu sürətli alın və maliyyə gücünüzü idarə edin.",
      imgUrl: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="53"
          height="52"
          viewBox="0 0 53 52"
          fill="none"
        >
          <path
            d="M36.8902 15.4064C36.6241 14.6519 36.1304 13.9986 35.4773 13.5364C34.8242 13.0743 34.0438 12.8261 33.2438 12.8262H30.2462C29.3977 12.8307 28.5806 13.1474 27.9507 13.716C27.3209 14.2845 26.9225 15.0651 26.8315 15.9087C26.7404 16.7522 26.9632 17.5998 27.4573 18.2896C27.9513 18.9794 28.682 19.4632 29.51 19.6486L34.071 20.6465C35.0017 20.8521 35.8238 21.3938 36.3799 22.168C36.9359 22.9422 37.1867 23.8943 37.0843 24.8419C36.9818 25.7895 36.5332 26.666 35.8246 27.3034C35.1159 27.9408 34.1969 28.2943 33.2438 28.2962H30.6635C29.8636 28.2956 29.0835 28.0473 28.4305 27.5852C27.7775 27.1231 27.2837 26.4701 27.0171 25.7159"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M31.9541 12.8235V8.95312"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M31.9541 32.1614V28.291"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M13.3975 20.5587C13.3975 25.4807 15.3527 30.2012 18.8332 33.6816C22.3136 37.162 27.0341 39.1173 31.9561 39.1173C36.8782 39.1173 41.5987 37.162 45.0791 33.6816C48.5595 30.2012 50.5148 25.4807 50.5148 20.5587C50.5148 15.6366 48.5595 10.9161 45.0791 7.43571C41.5987 3.95528 36.8782 2 31.9561 2C27.0341 2 22.3136 3.95528 18.8332 7.43571C15.3527 10.9161 13.3975 15.6366 13.3975 20.5587Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M4.24748 22.5938C2.37244 26.0482 1.64278 30.0087 2.1637 33.9046C2.68463 37.8004 4.42922 41.4301 7.14598 44.2705C9.86273 47.1109 13.4113 49.0153 17.2801 49.709C21.1488 50.4027 25.138 49.8499 28.6724 48.1304"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      id: 3,
      title: "Problemlərinizin Sürətli Həlli",
      content:
        "Mütəxəssislərimizə müraciət edərək problemlərinizi sürətli həll edin.",

      imgUrl: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="54"
          height="56"
          viewBox="0 0 54 56"
          fill="none"
        >
          <path
            d="M3.84474 4.29981L31.9198 12.2799C33.8223 12.9047 35.4837 14.1044 36.6755 15.7134C37.867 17.3225 38.5299 19.2617 38.5728 21.2635V48.2401C38.6529 49.0917 38.5243 49.9501 38.1983 50.7409C37.8721 51.5317 37.3582 52.2314 36.7011 52.7789C36.044 53.3266 35.2635 53.706 34.4267 53.8844C33.5902 54.0625 32.7227 54.0345 31.8992 53.8023L8.67537 47.488C6.77274 46.8961 5.10453 45.7206 3.90685 44.1282C2.70916 42.5357 2.0427 40.6068 2.00181 38.6148V9.22028C1.95964 7.35584 2.65651 5.55039 3.94038 4.19776C5.22424 2.84513 6.99094 2.05508 8.85505 2H44.348C46.3904 2 48.3491 2.81127 49.7931 4.25536C51.2372 5.69944 52.0483 7.65803 52.0483 9.70027V32.6804C52.0393 33.7187 51.8255 34.7446 51.4192 35.7C51.0128 36.6553 50.422 37.5211 49.6804 38.2477C48.9387 38.9741 48.0611 39.5473 47.0975 39.9341C46.1342 40.3206 45.1039 40.5136 44.0657 40.5013H38.5728"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M27.9849 32.801C27.4534 32.801 27.0224 32.3701 27.0224 31.8385C27.0224 31.3069 27.4534 30.876 27.9849 30.876"
            stroke="#092256"
            stroke-width="3"
          ></path>
          <path
            d="M27.9849 32.801C28.5165 32.801 28.9475 32.3701 28.9475 31.8385C28.9475 31.3069 28.5165 30.876 27.9849 30.876"
            stroke="#092256"
            stroke-width="3"
          ></path>
          <path
            d="M52.0483 21.251H38.5728"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      id: 4,
      title: "Biznesinizi genişləndirin",
      content:
        "Başqa yerlərdə yük axtarmadan istədiyiniz yerdən yük daşımağa başlaya bilərsiniz. İşlərinizi böyütmək heç bu qədər asan olmayıb.",

      imgUrl: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="53"
          height="53"
          viewBox="0 0 53 53"
          fill="none"
        >
          <path
            d="M47.5 34.5V41"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M5.25 41V18.25C5.25 17.3881 5.59242 16.5614 6.2019 15.9519C6.81139 15.3424 7.63806 15 8.5 15H16.625"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M31.9737 41C31.4097 41.9871 30.5948 42.8074 29.6116 43.3781C28.6283 43.9488 27.5119 44.2494 26.375 44.2494C25.2382 44.2494 24.1217 43.9488 23.1384 43.3781C22.1552 42.8074 21.3403 41.9871 20.7763 41H2V44.25C2 45.9738 2.68482 47.6272 3.90381 48.8461C5.1228 50.0651 6.77609 50.75 8.5 50.75H44.25C45.9738 50.75 47.6272 50.0651 48.8462 48.8461C50.0651 47.6272 50.75 45.9738 50.75 44.25V41H31.9737Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M41.0128 2.57718C38.4633 1.81536 35.748 1.80753 33.1944 2.55462C30.6405 3.30173 28.3577 4.77186 26.6209 6.78762C24.8841 8.80338 23.7679 11.2786 23.4065 13.9147C23.0453 16.5509 23.4546 19.2352 24.5851 21.6438L20.113 30.72L30.3463 27.925C31.2489 28.4422 32.2077 28.8543 33.2041 29.1535C36.7078 30.1225 40.4523 29.6746 43.6288 27.9071C46.8056 26.1395 49.1599 23.1937 50.1839 19.7056C51.2076 16.2174 50.8189 12.4665 49.1014 9.26239C47.3841 6.05828 44.4758 3.65793 41.0041 2.57935L41.0128 2.57718Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M33.1003 12.6436C33.1003 11.8401 33.3387 11.0546 33.785 10.3866C34.2313 9.71849 34.866 9.19777 35.6083 8.8903C36.3506 8.58283 37.1674 8.50236 37.9554 8.65912C38.7434 8.81587 39.4673 9.2028 40.0354 9.77094C40.6037 10.3391 40.9905 11.063 41.1473 11.851C41.304 12.639 41.2236 13.4559 40.9162 14.1982C40.6087 14.9405 40.0878 15.575 39.4199 16.0214C38.7519 16.4678 37.9662 16.7061 37.1628 16.7061"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M37.1628 23.2062C36.7141 23.2062 36.3503 22.8424 36.3503 22.3937C36.3503 21.9448 36.7141 21.5811 37.1628 21.5811"
            stroke="#092256"
            stroke-width="3"
          ></path>
          <path
            d="M37.1628 23.2062C37.6116 23.2062 37.9753 22.8424 37.9753 22.3937C37.9753 21.9448 37.6116 21.5811 37.1628 21.5811"
            stroke="#092256"
            stroke-width="3"
          ></path>
        </svg>
      ),
    },
    {
      id: 5,
      title: "Getdiyiniz Ünvandan Boş Qayıtmayın.",
      content:
        "Vectus Port ilə boş boş geri dönmələrə son qoyun! Maşınınıza  uyğun yükləri tapın və əlavə gəlir əldə edin. Biznesinizi ekoloji cəhətdən təmiz və gəlirli şəkildə idarə edin",

      imgUrl: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
        >
          <path
            d="M43.5993 37.704C43.1206 37.704 42.7326 37.316 42.7326 36.8374C42.7326 36.3585 43.1206 35.9707 43.5993 35.9707"
            stroke="#092256"
            stroke-width="3"
          ></path>
          <path
            d="M43.5992 37.704C44.0779 37.704 44.4659 37.316 44.4659 36.8374C44.4659 36.3585 44.0779 35.9707 43.5992 35.9707"
            stroke="#092256"
            stroke-width="3"
          ></path>
          <path
            d="M43.5992 26.4375C46.3575 26.4375 49.0027 27.5332 50.953 29.4835C52.9033 31.4338 53.999 34.079 53.999 36.8373C53.999 41.2769 47.7869 49.7261 44.965 53.3337C44.803 53.541 44.5957 53.709 44.3591 53.8244C44.1224 53.9397 43.8624 53.9998 43.5992 53.9998C43.336 53.9998 43.076 53.9397 42.8393 53.8244C42.6027 53.709 42.3954 53.541 42.2334 53.3337C39.4116 49.7238 33.1994 41.2769 33.1994 36.8373C33.1994 34.079 34.2951 31.4338 36.2454 29.4835C38.1957 27.5332 40.8409 26.4375 43.5992 26.4375Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M33.1973 53.4742C28.1631 54.5012 22.9374 54.0138 18.1799 52.0734C13.4225 50.1328 9.34665 46.8262 6.46704 42.571C3.58745 38.3157 2.0332 33.3027 2.00053 28.165C1.96785 23.027 3.45821 17.9946 6.28345 13.7031C9.10868 9.41162 13.1421 6.05343 17.8745 4.05253C22.6068 2.05164 27.826 1.49776 32.8729 2.46084C37.9198 3.42392 42.5683 5.86076 46.2313 9.46369C49.8944 13.0666 52.4076 17.6741 53.4541 22.7044"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M21.7418 53.2184C18.2259 48.0627 15.8766 38.6929 15.8766 27.9906C15.8766 17.2882 18.2259 7.92102 21.7418 2.7627"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M2.07391 26.2568H29.7368"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M7.21173 12.3975H48.7948"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M5.01465 40.1172H26.2716"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M34.267 2.7627C37.26 7.8201 39.0825 13.4835 39.6009 19.3372"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      id: 6,
      title: "Qazanc İzləmə İmtiyazı",
      content: "",

      imgUrl: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
        >
          <path
            d="M13.084 25.0626C13.084 26.7774 13.7652 28.4219 14.9777 29.6344C16.1902 30.8469 17.8347 31.5281 19.5494 31.5281C21.2642 31.5281 22.9087 30.8469 24.1212 29.6344C25.3337 28.4219 26.0149 26.7774 26.0149 25.0626C26.0149 23.3479 25.3337 21.7034 24.1212 20.4909C22.9087 19.2783 21.2642 18.5972 19.5494 18.5972C17.8347 18.5972 16.1902 19.2783 14.9777 20.4909C13.7652 21.7034 13.084 23.3479 13.084 25.0626Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M35.6573 32.4276C32.1549 41.8191 23.2215 49.9988 19.5491 49.9988C14.9309 49.9988 2 37.0679 2 25.0607C1.99978 22.6189 2.50913 20.204 3.49546 17.9703C4.4818 15.7366 5.92342 13.7333 7.72813 12.0885C9.53285 10.4438 11.6609 9.19373 13.9763 8.41834C16.2917 7.64295 18.7434 7.35928 21.1747 7.58546"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M28.5153 14.0669C27.2813 13.8489 27.2813 12.0792 28.5153 11.8649C30.6992 11.4853 32.7206 10.4637 34.3215 8.93053C35.9225 7.39736 37.0305 5.42207 37.5042 3.25661L37.578 2.91671C37.8441 1.69751 39.5805 1.69382 39.8576 2.90932L39.9463 3.30464C40.438 5.46069 41.5561 7.42327 43.1601 8.94569C44.764 10.4681 46.7822 11.4825 48.9609 11.8612C50.1986 12.0755 50.1986 13.8563 48.9609 14.0705C46.7822 14.4493 44.764 15.4636 43.1601 16.9861C41.5561 18.5085 40.438 20.4711 39.9463 22.6271L39.8576 23.0224C39.5805 24.2379 37.8441 24.2305 37.578 23.0113L37.5042 22.6714C37.0305 20.506 35.9225 18.5307 34.3215 16.9975C32.7206 15.4644 30.6992 14.4465 28.5153 14.0669Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
  ];

  const cards = [
    {
      id: 1,
      title: "Rahat, Sürətli və Güvənli müştəri tapa bilmək",
      content:
        "Siz yükünüzü təhvil verdikdən sonra yük ödənişləri komissiyasız və ödəmə müddətində hesabınıza yatırılır. Pulunuzu rəvan və tez alacaqsınız.",
      imgUrl: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="61"
          height="48"
          viewBox="0 0 61 60"
          fill="none"
        >
          <path
            d="M42.706 17.6392C42.3955 16.759 41.8195 15.9967 41.0575 15.4576C40.2956 14.9184 39.3851 14.6289 38.4517 14.6289H34.9544C33.9645 14.6342 33.0112 15.0037 32.2764 15.667C31.5416 16.3303 31.0767 17.241 30.9705 18.2252C30.8643 19.2094 31.1242 20.1983 31.7006 21.0031C32.277 21.8079 33.1296 22.3723 34.0956 22.5885L39.4168 23.7528C40.5026 23.9926 41.4618 24.6247 42.1106 25.5279C42.7593 26.4311 43.0519 27.5419 42.9324 28.6475C42.8128 29.7531 42.2895 30.7757 41.4627 31.5194C40.6359 32.263 39.5637 32.6754 38.4517 32.6776H35.4414C34.5081 32.6769 33.5979 32.3872 32.8361 31.8481C32.0743 31.309 31.4982 30.5471 31.1871 29.6672"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M36.9473 14.6288V10.1133"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M36.9473 37.1913V32.6758"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M15.2969 23.6522C15.2969 29.3947 17.5781 34.902 21.6387 38.9626C25.6992 43.0232 31.2065 45.3044 36.9491 45.3044C42.6916 45.3044 48.1989 43.0232 52.2595 38.9626C56.32 34.902 58.6012 29.3947 58.6012 23.6522C58.6012 17.9097 56.32 12.4023 52.2595 8.34178C48.1989 4.2812 42.6916 2 36.9491 2C31.2065 2 25.6992 4.2812 21.6387 8.34178C17.5781 12.4023 15.2969 17.9097 15.2969 23.6522Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M4.62211 26.0234C2.43452 30.0536 1.58324 34.6744 2.19099 39.2196C2.79875 43.7648 4.83415 47.9996 8.00375 51.3135C11.1734 54.6274 15.3134 56.8491 19.8271 57.6585C24.3407 58.4678 28.9948 57.8229 33.1183 55.8168"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      id: 2,
      title: "24/7 Telefon Dəstəyi",
      content:
        "Kamion dəstək komandası ehtiyac duyduğunuz zaman sizin üçün hazırdır. Hər hansı problemlə qarşılaşdığınız zaman və ya məlumat ehtiyacınız olduqda, mütəxəssislərimizlə əlaqə saxlaya bilərsiniz.",
      imgUrl: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="48"
          viewBox="0 0 56 56"
          fill="none"
        >
          <path
            d="M35.0249 51.9788L35.0533 51.9987C37.5088 53.5624 40.4243 54.2413 43.318 53.9236C46.2119 53.6058 48.9104 52.3102 50.9682 50.2509L52.7586 48.4633C53.1548 48.0675 53.4691 47.5974 53.6834 47.0802C53.8977 46.5627 54.0082 46.0082 54.0082 45.448C54.0082 44.8882 53.8977 44.3334 53.6834 43.8162C53.4691 43.2987 53.1548 42.8286 52.7586 42.4327L45.2161 34.8987C44.4167 34.0996 43.3325 33.6508 42.2022 33.6508C41.072 33.6508 39.9878 34.0996 39.1884 34.8987C38.7925 35.2949 38.3224 35.6092 37.8049 35.8235C37.2877 36.0381 36.7332 36.1483 36.1731 36.1483C35.6129 36.1483 35.0584 36.0381 34.5412 35.8235C34.0237 35.6092 33.5536 35.2949 33.1577 34.8987L21.1022 22.8404C20.303 22.0409 19.8541 20.957 19.8541 19.8265C19.8541 18.6963 20.303 17.6121 21.1022 16.8126C21.4984 16.4167 21.8126 15.9468 22.027 15.4294C22.2414 14.912 22.3518 14.3575 22.3518 13.7974C22.3518 13.2374 22.2414 12.6828 22.027 12.1654C21.8126 11.6481 21.4984 11.178 21.1022 10.7821L13.5654 3.24813C12.766 2.44894 11.6819 2 10.5515 2C9.42112 2 8.33703 2.44894 7.53762 3.24813L5.7472 5.03571C3.68846 7.09379 2.39348 9.79264 2.0762 12.6863C1.75893 15.58 2.43837 18.4953 4.00224 20.9505L4.01929 20.9789C12.2785 33.1987 22.8036 43.7218 35.0249 51.9788Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      id: 3,
      title: "Geri Dönüş Yükü Tapmaq",
      content:
        "Həm ekoloji cəhətdən təmiz, həm də sərfəli, Vectus Port ilə getdiyiniz ünvanda sizə yük tapmağa kömək edir.",
      imgUrl: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="72"
          height="48"
          viewBox="0 0 72 56"
          fill="none"
        >
          <path
            d="M43.6016 12.3984H59.2022C61.9604 12.3984 64.6056 13.4942 66.5562 15.4448C68.5068 17.3949 69.6026 20.0406 69.6026 22.7988V43.5996H61.8023"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M7.2002 43.6015H2V7.20019C2 5.82108 2.54814 4.49822 3.52291 3.52291C4.49822 2.54761 5.82108 2 7.2002 2H38.4014C39.7805 2 41.1034 2.54814 42.0787 3.52291C43.054 4.49822 43.6016 5.82108 43.6016 7.20019V43.6015"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M41.0015 43.6016H28.001"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M52.702 54.0005C47.6758 54.0005 43.6016 49.9263 43.6016 44.9001C43.6016 39.874 47.6758 35.7998 52.702 35.7998C57.7281 35.7998 61.8023 39.874 61.8023 44.9001C61.8023 49.9263 57.7281 54.0005 52.702 54.0005Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M17.6006 54.0005C12.5744 54.0005 8.50024 49.9263 8.50024 44.9001C8.50024 39.874 12.5744 35.7998 17.6006 35.7998C22.6267 35.7998 26.7009 39.874 26.7009 44.9001C26.7009 49.9263 22.6267 54.0005 17.6006 54.0005Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      id: 4,
      title: "Sizin üçün Əlavə Qazanc",
      content:
        "Yüzlərlə etibarlı müştərilərə çıxış əldə edin və siz vaxt itirmədən istədiyiniz yerdən daşımalara başlaya bilərsiniz.",
      imgUrl: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="48"
          viewBox="0 0 56 60"
          fill="none"
        >
          <path
            d="M31.8096 56.7515L29.2681 57.7423C28.3515 58.0859 27.3415 58.0859 26.4249 57.7423L23.8834 56.7515C17.4458 54.2277 11.9163 49.8256 8.0136 44.1176C4.11094 38.4096 2.01562 31.6595 2 24.7449V12.7694C6.8785 13.4646 11.8542 12.8313 16.4029 10.936C20.9517 9.04065 24.905 5.95359 27.8465 2C33.2312 9.8401 42.1482 13.5448 53.693 12.7694V24.7449C53.6774 31.6595 51.5821 38.4096 47.6794 44.1176C43.7767 49.8256 38.2472 54.2277 31.8096 56.7515Z"
            stroke="#092256"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      ),
    },
  ];
  return (
    <div>
      <Navbar />
      <div className="bg-[#092256] text-center py-[30px]">
        <h1 className="text-center text-[#7EAFE8] text-[14px] lg:text-[20px] py-[15px] lg:py-[40px]">
          Yük daşıyanlar
        </h1>
        <h2 className="text-white text-[20px] lg:text-[32px]">
          Sizə uyğun yüklər, Sürətli Ödəmə
        </h2>
        <div className="w-[80%] mx-auto lg:w-[100%] pt-[30px] flex items-center justify-center">
          <figure>
            <img
              alt="Yük Verenler"
              src={shipperImage}
            />
          </figure>
        </div>
      </div>
      <h3 className="text-center text-[#092256] text-[24px] py-[50px]">
        Niyə Vectus Port?
      </h3>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 mt-2  lg:mt-16 w-[80%] mx-auto">
        {cards?.map((card) => {
          return (
            <div className="p-[20px] rounded-[8px] border-[1px] border-solid border-[#F1F1F1] hover:border-[#000] cursor-pointer">
              <div className="pt-6">{card?.imgUrl}</div>
              <div>
                <h4 className="text-[#092256] text-[20px] pt-4">
                  {card?.title}
                </h4>
                <p className="pt-4 opacity-[0.7] text-[12px]">
                  {card?.content}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <h3 className="text-center text-[#092256] text-[24px] py-[50px]">
        Elə indi qazanmağa başlayın!
      </h3>
      <div className="w-[95%] lg:w-[80%] mx-auto flex lg:flex-row flex-col lg:items-center justify-between gap-6">
        <div className="lg:w-[80%]">
          <ul className="flex flex-wrap">
            <li className="py-[20px] px-[24px] flex  gap-2 cursor-pointer">
              <span className="text-[12px]">01</span>
              <div>
                <h4 className="text-[#031742] text-[14px] lg:text-[22px] opacity-[0.8]">
                  Vectus Port mobil tətbiqini Pulsuz Yükləyin və Qeydiyyatdan
                  Keçin
                </h4>
                <p className="opacity-[0.7]  text-[10px] lg:text-[14px] pt-2">
                  Mobil tətbiqimizi yükləyərək sürətli qeydiyyatdan keçə
                  bilərsiniz. Asan və təhlükəsiz interfeys ilə işinizi
                  asanlaşdırır və qazancınızı artırmağa kömək edir.
                </p>
              </div>
            </li>
            <li className="py-[20px] px-[24px] flex  gap-2 cursor-pointer">
              <span className="text-[12px]">02</span>
              <div>
              <h4 className="text-[#031742] text-[14px] lg:text-[22px] opacity-[0.8]">
              Tələb olunan sənədləri Vectus Port hesabına yükləyin
                </h4>
                <p className="opacity-[0.7]  text-[10px] lg:text-[14px] pt-2">
                  Yük Daşıyan biznes partnyoru olmaq üçün sadəcə olaraq
                  sürücülük vəsiqəniz və nəqliyyat vasitəsinin qeydiyyatı kimi
                  bəzi sənədləri yükləməlisiniz və lazımi məlumatları
                  doldurmalısınız.
                </p>
              </div>
            </li>
            <li className="py-[20px] px-[24px] flex  gap-2 cursor-pointer">
              <span className="text-[12px]">03</span>
              <div>
              <h4 className="text-[#031742] text-[14px] lg:text-[22px] opacity-[0.8]">
              Yükləri kəşf edin, əlavə qazanc əldə edin
                </h4>
                <p className="opacity-[0.7]  text-[10px] lg:text-[14px] pt-2">
                  Tətbiqə daxil olduqda siz bütün aktiv daşıma elanlarını görə
                  və yükün bütün ətraflı məlumatlarına əldə edə biləcəksiniz.
                  Sizə uyğun olan yükü tapdığınız zaman bir kliklə qəbul edib
                  daşımağa başlaya bilərsiniz. Çatdırılan yük üçün ödənişi
                  asanlıqla ala bilərsiniz.
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div class="tab-pane active">
          <img
            alt="Nasil Çalışır"
            src="https://www.kamion.co/wp-content/themes/kamion/img/sp-nasil-1.webp"
          />
        </div>
      </div>
      <div className="bg-[#F2F3F4] mt-4">
        <div className="w-[80%] mx-auto">
          <h2 className="text-[20px] py-[28px]">Üstünlüklər</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
            {advantages?.map((advantage) => {
              return (
                <div className="p-[20px] rounded-[8px] border-[1px] bg-white shadow-md  border-solid border-[#F1F1F1] hover:border-[#000] cursor-pointer">
                  <div className="pt-6">{advantage?.imgUrl}</div>
                  <div>
                    <h4 className="text-[#092256] text-[20px] pt-4">
                      {advantage?.title}
                    </h4>
                    <p className="pt-4 opacity-[0.7] text-[12px]">
                      {advantage?.content}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div>
        <Questions />
      </div>
      <div className="bg-[#092256]">
        <Footer />
      </div>
    </div>
  );
};

export default DriverScreen;
