import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  paymentDetailsData: null,
  paymentDetailsLoading: false,
  paymentDetailsError: null,
  paymentUpdateSuccess: null,
  paymentUpdateLoading: false,
  paymentUpdateError: null,
  paymentDeleteSuccess: null,
  paymentDeleteLoading: false,
  paymentDeleteError: null,
  paymentCreateSuccess: null,
  paymentCreateLoading: false,
  paymentCreateError: null,
};

export const paymentList = createAsyncThunk(
  "payment/paymentList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/payment/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const paymentDetails = createAsyncThunk(
  "payment/paymentDetails",
  async ({id}, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/payment/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const updatepayment = createAsyncThunk(
  "payment/paymentUpdate",
  async ({ id, updatedpayment }, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/payment/${id}`,
        updatedpayment,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const paymentCreate = createAsyncThunk(
  "payment/paymentCreate",
  async ({ payment }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/payment`,
        payment,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);



export const paymentDelete = createAsyncThunk(
    "payment/paymentDelete",
    async ({ id }, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.delete(
          `${apiConfig.API_URL}/api/payment/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.log(rejectWithValue(error.response.data));
      }
    }
  );
  

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(paymentList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(paymentList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(paymentList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(paymentDetails.pending, (state) => {
        state.paymentDetailsLoading = true;
        state.paymentDetailsError = null;
      })
      .addCase(paymentDetails.fulfilled, (state, action) => {
        state.paymentDetailsLoading = false;
        state.paymentDetailsData = action.payload;
      })
      .addCase(paymentDetails.rejected, (state, action) => {
        state.paymentDetailsLoading = false;
        state.paymentDetailsError = action.payload;
      })
      
      .addCase(updatepayment.pending, (state) => {
        state.paymentUpdateLoading = true;
        state.paymentUpdateError = null;
      })
      .addCase(updatepayment.fulfilled, (state, action) => {
        state.paymentUpdateLoading = false;
        state.paymentUpdateSuccess = action.payload.success;
      })
      .addCase(updatepayment.rejected, (state, action) => {
        state.paymentUpdateLoading = false;
        state.paymentUpdateError = action.payload;
      })
      .addCase(paymentCreate.pending, (state) => {
        state.paymentCreateLoading = true;
        state.paymentCreateError = false;
      })
      .addCase(paymentCreate.fulfilled, (state, action) => {
        state.paymentCreateLoading = false;
        state.paymentCreateSuccess = true;
      })
      .addCase(paymentCreate.rejected, (state, action) => {
        state.paymentCreateLoading = false;
        state.paymentCreateError = true;
      })
      .addCase(paymentDelete.pending, (state) => {
        state.paymentDeleteLoading = true;
        state.paymentDeleteError = false;
      })
      .addCase(paymentDelete.fulfilled, (state, action) => {
        state.paymentDeleteLoading = false;
        state.paymentDeleteSuccess = true;
      })
      .addCase(paymentDelete.rejected, (state, action) => {
        state.paymentDeleteLoading = false;
        state.paymentDeleteError = true;
      });
  },
});

// Export actions and reducer
export const paymentActions = paymentSlice.actions;
export default paymentSlice.reducer;
