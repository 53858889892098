import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  orderDetails: null,
  orderDetailsLoading: false,
  orderDetailsError: null,
  orderUpdateSuccess: null,
  orderUpdateLoading: false,
  orderUpdateError: null,
  orderDelete: null,
  orderDeleteLoading: false,
  orderDeleteError: null,
  orderCreateSuccess: null,
  orderCreateLoading: false,
  orderCreateError: null,
  adminOrderCreateSuccess: null,
  adminOrderCreateLoading: false,
  adminOrderCreateError: null,
};

export const orderList = createAsyncThunk(
  "order/orderList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/orders/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const orderDetails = createAsyncThunk(
  "order/orderDetails",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/orders/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);


export const orderUpdate = createAsyncThunk(
  "order/orderUpdate",
  async (
    {updatedOrder, id },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/orders/${id}`,
        updatedOrder,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const orderDelete = createAsyncThunk(
  "order/orderDelete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${apiConfig.API_URL}/api/orders/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const orderCreate = createAsyncThunk(
  "order/orderCreate",
  async ({ order }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/orders/create`,
        order,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const adminOrderCreate = createAsyncThunk(
  "order/adminOrderCreate",
  async ({ id,order }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/orders/admin/order/${id}`,
        order,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);


const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(orderList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(orderList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(orderList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(orderDetails.pending, (state) => {
        state.orderDetailsLoading = true;
        state.orderDetailsError = null;
      })
      .addCase(orderDetails.fulfilled, (state, action) => {
        state.orderDetailsLoading = false;
        state.orderDetails = action.payload;
      })
      .addCase(orderDetails.rejected, (state, action) => {
        state.orderDetailsLoading = false;
        state.orderDetailsError = action.payload;
      })
      
      .addCase(orderCreate.pending, (state) => {
        state.orderCreateLoading = true;
        state.orderCreateError = false;
      })
      .addCase(orderCreate.fulfilled, (state, action) => {
        state.orderCreateLoading = false;
        state.orderCreateSuccess = action.payload.success;
      })
      .addCase(orderCreate.rejected, (state, action) => {
        state.orderCreateLoading = false;
        state.orderCreateError = true;
      })

      .addCase(orderUpdate.pending, (state) => {
        state.orderUpdateLoading = true;
        state.orderUpdateError = false;
      })
      .addCase(orderUpdate.fulfilled, (state, action) => {
        state.orderUpdateLoading = false;
        state.orderUpdateSuccess = action.payload.success;
      })
      .addCase(orderUpdate.rejected, (state, action) => {
        state.orderUpdateLoading = false;
        state.orderUpdateError = true;
      })
      
  },
});

// Export actions and reducer
export const orderActions = orderSlice.actions;
export default orderSlice.reducer;
