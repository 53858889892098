import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import orderReducer from "./slices/orderSlice";
import userReducer from "./slices/userSlice";
import companyReducer from "./slices/companySlice";
import driverReducer from "./slices/driverSlice";
import carReducer from "./slices/carSlice";
import brandReducer from "./slices/brandSlice";
import trackingReducer from "./slices/trackingSlice";
import banReducer from "./slices/banSlice";
import carTypeReducer from "./slices/carTypeSlice"; 
import paymentReducer from "./slices/paymentSlice";
import loadReducer from "./slices/loadSlice";
import addressReducer from "./slices/addressSlice";
import blogReducer from "./slices/blogSlice";
import contactReducer from "./slices/contactSlice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    order: orderReducer,
    user: userReducer,
    company: companyReducer,
    driver: driverReducer,
    car: carReducer,
    tracking: trackingReducer,
    brand: brandReducer,
    ban: banReducer,
    carType: carTypeReducer,
    load: loadReducer,
    payment: paymentReducer,
    address: addressReducer,
    blog: blogReducer,
    contact: contactReducer,
  },
});

export default store;