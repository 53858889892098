import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  driverDetailsData: null,
  driverDetailsLoading: false,
  driverDetailsError: null,
  driverUpdateSuccess: null,
  driverUpdateLoading: false,
  driverUpdateError: null,
  driverStatusUpdateSuccess: null,
  driverStatusUpdateLoading: false,
  driverStatusUpdateError: null,
  driverDeleteSuccess: null,
  driverDeleteLoading: false,
  driverDeleteError: null,
  driverCreateSuccess: null,
  driverCreateLoading: false,
  driverCreateError: null,
};

export const driverList = createAsyncThunk(
  "driver/driverList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/drivers/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const driverDetails = createAsyncThunk(
  "driver/driverDetails",
  async ({id},{ rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/drivers/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );    
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);





export const driverUpdate = createAsyncThunk(
  "driver/driverUpdate",
  async ({ updatedDriver, id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/drivers/${id}`,
        updatedDriver,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const driverCreate = createAsyncThunk(
  "driver/driverCreate",
  async ({ driver }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/drivers`,
        driver,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);


export const driverStatusUpdate = createAsyncThunk(
  "driver/driverStatusUpdate",
  async ({ id,status }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/drivers/${id}/${status}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const driverDelete = createAsyncThunk(
  "driver/driverDelete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${apiConfig.API_URL}/api/drivers/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(driverList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(driverList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(driverList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(driverDetails.pending, (state) => {
        state.driverDetailsLoading = true;
        state.driverDetailsError = null;
      })
      .addCase(driverDetails.fulfilled, (state, action) => {
        state.driverDetailsLoading = false;
        state.driverDetailsData = action.payload;
      })
      .addCase(driverDetails.rejected, (state, action) => {
        state.driverDetailsLoading = false;
        state.driverDetailsError = action.payload;
      })
      
      .addCase(driverUpdate.pending, (state) => {
        state.driverUpdateLoading = true;
        state.driverUpdateError = null;
      })
      .addCase(driverUpdate.fulfilled, (state, action) => {
        state.driverUpdateLoading = false;
        state.driverUpdateSuccess = action.payload.success;
      })
      .addCase(driverUpdate.rejected, (state, action) => {
        state.driverUpdateLoading = false;
        state.driverUpdateError = action.payload;
      })
      .addCase(driverStatusUpdate.pending, (state) => {
        state.driverStatusUpdateLoading = true;
        state.driverStatusUpdateError = null;
      })
      .addCase(driverStatusUpdate.fulfilled, (state, action) => {
        state.driverStatusUpdateLoading = false;
        state.driverStatusUpdateSuccess = action.payload.success;
      })
      .addCase(driverStatusUpdate.rejected, (state, action) => {
        state.driverStatusUpdateLoading = false;
        state.driverStatusUpdateError = action.payload;
      })
      .addCase(driverCreate.pending, (state) => {
        state.driverCreateLoading = true;
        state.driverCreateError = false;
      })
      .addCase(driverCreate.fulfilled, (state, action) => {
        state.driverCreateLoading = false;
        state.driverCreateSuccess = action.payload.success;
      })
      .addCase(driverCreate.rejected, (state, action) => {
        state.driverCreateLoading = false;
        state.driverCreateError = true;
      })
      .addCase(driverDelete.pending, (state) => {
        state.driverDeleteLoading = true;
        state.driverDeleteError = null;
      })
      .addCase(driverDelete.fulfilled, (state, action) => {
        state.driverDeleteLoading = false;
        state.driverDeleteSuccess = action.payload.success;
      })
      .addCase(driverDelete.rejected, (state, action) => {
        state.driverDeleteLoading = false;
        state.driverDeleteError = action.payload;
      })
  },
});

// Export actions and reducer
export const driverActions = driverSlice.actions;
export default driverSlice.reducer;
