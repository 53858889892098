import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

const initialState = {
  data: null,
  loading: false,
  error: null,
  brandDetailsData: null,
  brandDetailsLoading: false,
  brandDetailsError: null,
  brandUpdateSuccess: null,
  brandUpdateLoading: false,
  brandUpdateError: null,
  brandDeleteSuccess: null,
  brandDeleteLoading: false,
  brandDeleteError: null,
  brandCreateSuccess: null,
  brandCreateLoading: false,
  brandCreateError: null,
};

export const brandList = createAsyncThunk(
  "brand/brandList",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/brands/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const brandDetails = createAsyncThunk(
  "brand/brandDetails",
  async ({id}, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiConfig.API_URL}/api/brands/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const updateBrand = createAsyncThunk(
  "brand/brandUpdate",
  async ({ id, updatedBrand }, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiConfig.API_URL}/api/brands/${id}`,
        updatedBrand,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  }
);

export const brandCreate = createAsyncThunk(
  "brand/brandCreate",
  async ({ brand }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiConfig.API_URL}/api/brands`,
        brand,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);



export const brandDelete = createAsyncThunk(
    "brand/brandDelete",
    async ({ id }, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.delete(
          `${apiConfig.API_URL}/api/brands/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.log(rejectWithValue(error.response.data));
      }
    }
  );
  

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(brandList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(brandList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(brandList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(brandDetails.pending, (state) => {
        state.brandDetailsLoading = true;
        state.brandDetailsError = null;
      })
      .addCase(brandDetails.fulfilled, (state, action) => {
        state.brandDetailsLoading = false;
        state.brandDetailsData = action.payload;
      })
      .addCase(brandDetails.rejected, (state, action) => {
        state.brandDetailsLoading = false;
        state.brandDetailsError = action.payload;
      })
      
      .addCase(updateBrand.pending, (state) => {
        state.brandUpdateLoading = true;
        state.brandUpdateError = null;
      })
      .addCase(updateBrand.fulfilled, (state, action) => {
        state.brandUpdateLoading = false;
        state.brandUpdateSuccess = action.payload.success;
      })
      .addCase(updateBrand.rejected, (state, action) => {
        state.brandUpdateLoading = false;
        state.brandUpdateError = action.payload;
      })
      .addCase(brandCreate.pending, (state) => {
        state.brandCreateLoading = true;
        state.brandCreateError = false;
      })
      .addCase(brandCreate.fulfilled, (state, action) => {
        state.brandCreateLoading = false;
        state.brandCreateSuccess = true;
      })
      .addCase(brandCreate.rejected, (state, action) => {
        state.brandCreateLoading = false;
        state.brandCreateError = true;
      })
      .addCase(brandDelete.pending, (state) => {
        state.brandDeleteLoading = true;
        state.brandDeleteError = false;
      })
      .addCase(brandDelete.fulfilled, (state, action) => {
        state.brandDeleteLoading = false;
        state.brandDeleteSuccess = true;
      })
      .addCase(brandDelete.rejected, (state, action) => {
        state.brandDeleteLoading = false;
        state.brandDeleteError = true;
      });
  },
});

// Export actions and reducer
export const brandActions = brandSlice.actions;
export default brandSlice.reducer;
